import React from "react";
import PropTypes from "prop-types";
import {ToggleButton} from "primereact/togglebutton";
import {Button} from 'primereact/button';
import {Sidebar} from "primereact/sidebar";

class SettingsSelectDayAndTimeComponent extends React.Component {

    constructor(props) {
        super(props);

        let active = this.props.active !== undefined ? this.props.active : false;
        let value = this.props.value !== undefined ? this.props.value : "";
        this.state = {active: active, value: value};
        this.removeLastValue = this.removeLastValue.bind(this);
        this.deleteValue = this.deleteValue.bind(this);
        this.addValue = this.addValue.bind(this);
        this.uebernehmeValue = this.uebernehmeValue.bind(this);
        this.abbrechen = this.abbrechen.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);
        this.changeJaNein = this.changeJaNein.bind(this);
    }

    hideSidebar() {
        if (this.state.value.length !== 5) {
            this.setState({...this.state, visible: true});
        } else {
            this.setState({...this.state, visible: false});
        }
    }

    uebernehmeValue() {
        this.setState({...this.state, visible: false});
        this.props.onChange(this.props.day, this.state.active, this.state.value);
    }

    abbrechen() {
        this.setState({...this.state, visible: false, value: ""})
        this.props.onChange(this.props.day, this.state.active, "");
    }

    changeJaNein(e) {
        this.setState({active: e.value});
        this.props.onChange(this.props.day, e.value, this.state.value);
    }

    addValue(value) {
        if (this.state.value.length === 2) {
            value = ":" + value;
        }
        let newValue = this.state.value + value;
        this.setState({...this.state, value: newValue})
        this.props.onChange(this.props.day, this.state.active, newValue);
    }

    deleteValue() {
        this.setState({...this.state, value: ""})
        this.props.onChange(this.props.day, this.state.active, "");
    }

    removeLastValue() {
        let newValue = this.state.value;
        let removeValue = 1;
        if (newValue.length === 3) {
            removeValue = 2;
        }
        newValue = newValue.substring(0, newValue.length - removeValue);
        this.setState({...this.state, value: newValue})
        this.props.onChange(this.props.day, this.state.active, newValue);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active !== prevState.active ||
            this.props.value !== prevState.value) {
            this.setState({...this.state, active: this.props.active, value: this.props.value});
        }
    }

    render() {
        let buttonStyle = {width: "80px", height: "80px"};
        let disableUebernehmenButton = this.state.value.length !== 5;
        let disableBackButton = false;
        let lengthFromValueIs0 = this.state.value.length === 0;
        let lengthFromValueIs2 = this.state.value.length === 2 || this.state.value.length === 3;
        let disableButtons = this.state.value.length === 5;
        let disableButton_1 = disableButtons;
        let disableButton_2 = disableButtons;
        let disableButton_3 = lengthFromValueIs0 || disableButtons;
        let disableButton_4 = lengthFromValueIs0 || disableButtons;
        let disableButton_5 = lengthFromValueIs0 || disableButtons;
        let disableButton_6 = lengthFromValueIs0 || lengthFromValueIs2 || disableButtons;
        let disableButton_7 = lengthFromValueIs0 || lengthFromValueIs2 || disableButtons;
        let disableButton_8 = lengthFromValueIs0 || lengthFromValueIs2 || disableButtons;
        let disableButton_9 = lengthFromValueIs0 || lengthFromValueIs2 || disableButtons;
        let disableButton_0 = disableButtons;
        return <div style={{marginBottom:"20px"}}>
            <div className="grid">
                <div className="col-fixed"
                     style={{width: '100px', textAlign: "left"}}>{this.props.beschriftung}</div>
                <div className="col-fixed"
                     style={{width: '100px', textAlign: "left"}}>{this.state.value}</div>
            </div>
            <div className="grid">
                <div className="col-fixed" style={{width: '70px', textAlign: "right"}}>
                    <ToggleButton checked={this.props.active} onChange={(e) => this.changeJaNein(e)}
                                  onLabel="Ja" offLabel="Nein"/>
                </div>
                <div className="col" style={{textAlign: "left"}}>
                    <Button label="Auswählen" style={{marginRight: "10px"}}
                            onClick={(e) => this.setState({visible: true})}/>
                    <Button icon="pi pi-times" style={{marginRight: "10px"}} disabled={!this.props.value}
                            onClick={(e) => this.abbrechen()}/>
                </div>
                <Sidebar visible={this.state.visible} style={{width: "350px"}}
                         onHide={(e) => this.hideSidebar()}
                         position="left" showCloseIcon={false}>
                    <div className="grid" style={{
                        textAlign: "center",
                        align: "center",
                        margin: "20px",
                        marginLeft: "70px",
                        fontWeight: "bold",
                        fontSize: "30px",
                        height: "50px"
                    }}>
                        {this.props.value}
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="1" style={buttonStyle} onClick={(e) => this.addValue(1)}
                                disabled={disableButton_1}/>
                        <Button label="2" style={buttonStyle} onClick={(e) => this.addValue(2)}
                                disabled={disableButton_2}/>
                        <Button label="3" style={buttonStyle} onClick={(e) => this.addValue(3)}
                                disabled={disableButton_3}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="4" style={buttonStyle} onClick={(e) => this.addValue(4)}
                                disabled={disableButton_4}/>
                        <Button label="5" style={buttonStyle} onClick={(e) => this.addValue(5)}
                                disabled={disableButton_5}/>
                        <Button label="6" style={buttonStyle} onClick={(e) => this.addValue(6)}
                                disabled={disableButton_6}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button label="7" style={buttonStyle} onClick={(e) => this.addValue(7)}
                                disabled={disableButton_7}/>
                        <Button label="8" style={buttonStyle} onClick={(e) => this.addValue(8)}
                                disabled={disableButton_8}/>
                        <Button label="9" style={buttonStyle} onClick={(e) => this.addValue(9)}
                                disabled={disableButton_9}/>
                    </div>
                    <div className="grid" style={{height: "80px", padding: "0px", margin: "0px"}}>
                        <Button icon="pi pi-times" style={buttonStyle}
                                onClick={(e) => this.deleteValue()}/>
                        <Button label="0" style={buttonStyle} onClick={(e) => this.addValue(0)}
                                disabled={disableButton_0}/>
                        <Button icon="pi pi-angle-left" style={buttonStyle} onClick={(e) => this.removeLastValue()}
                                disabled={disableBackButton}/>
                    </div>
                    <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                        <Button label="Übernehmen" className="p-button-success"
                                style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                onClick={() => this.uebernehmeValue()} disabled={disableUebernehmenButton}/>
                    </div>
                    <div className="grid" style={{padding: "0px", marginTop: "30px"}}>
                        <Button label="Abbrechen / Schliessen" className="p-button-danger"
                                style={{marginLeft: "5px", height: "50px", width: "240px"}}
                                onClick={() => this.abbrechen()}/>
                    </div>
                </Sidebar>
            </div>
        </div>
    }
}


SettingsSelectDayAndTimeComponent.propTypes = {
    beschriftung: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired

};

export default SettingsSelectDayAndTimeComponent;
