import React from 'react'

import {Panel} from 'primereact/panel';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";


import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";

import {InputText} from 'primereact/inputtext';
import ConfirmDialog from "../../general/ConfirmDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";


class SettingsTeams extends React.Component {

    constructor(props) {
        super(props);

        this.state = {id:undefined, bezeichnung:"", teams: []};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.loescheTeam = this.loescheTeam.bind(this);
        this.getRows = this.getRows.bind(this);
        this.getEditArea = this.getEditArea.bind(this);
        this.save = this.save.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/team",
            json => {
                this.setState({teams: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Mannschaften: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Mannschaften: " + error.message);
            })
    }

    save() {
        this.showError(undefined);
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.settings + "/team",
            {id: this.state.id, bezeichnung: this.state.bezeichnung},
            id => {
                this.setState({id: id});
                this.showInfo("Mannschaft wurde erfolgreich gespeichert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError(responseNotOk.message);
            },
            error => {
                this.showError(error.message);
            }
        );
    }

    loescheTeam() {
        this.showError(undefined);
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.settings + "/team",
            {id: this.state.id},
            () => {
                this.setState({id: undefined, bezeichnung: "", showDeleteDialog: false});
                this.showInfo("Mannschaft wurde erfolgreich gespeichert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError(responseNotOk.message);
            },
            error => {
                this.showError(error.message);
            }
        );
    }

    getEditArea() {
        return <div className="grid">
            <div className="col-fixed" style={{width:100}}>Bezeichnung:</div>
            <div className="col-fixed" style={{width:300}}>
                <InputText name="bezeichnung" className={!this.state.name ? "p-error" : ""} style={{width: "250px"}}
                           value={this.state.bezeichnung} onChange={(e) => this.setState({bezeichnung: e.target.value})}/>
            </div>
            <div className="col-fixed" style={{width:300}}>
                <div>
                    <Button label="Speichern" icon="pi pi-save" disabled={this.state.bezeichnung.length === 0} onClick={() => this.save()} style={{marginRight: "5px"}}/>
                    <Button icon="pi pi-times" onClick={() => this.setState({id:undefined, bezeichnung:""})} style={{marginRight: "5px"}}/>
                </div>
            </div>
            <div className="col-fixed" style={{width:50}}>

            </div>
        </div>
    }

    getRows() {
        let rows = [];

        for (let i = 0; i < this.state.teams.length; i++) {
            let team = this.state.teams[i];
            rows.push(<div key={"row_" + team.id} className="grid" style={{marginLeft:"5px", marginTop:10}}>
                <div className="col-fixed" style={{width:'95px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    <Button icon="pi pi-times"
                            tooltip="Team löschen"
                            onClick={() => this.setState({showDeleteDialog:true, id:team.id, bezeichnung:team.bezeichnung})}
                            style={{marginRight:"5px"}} />
                    <Button icon="pi pi-pencil" tooltip="Team ändern" style={{marginRight:"5px"}}
                            onClick={() => this.setState({id:team.id, bezeichnung:team.bezeichnung})}/>
                </div>
                <div className="col" style={{width:'350px', textAlign:"left", borderRight:"1px solid", borderBottom:"1px solid"}}>
                    {team.bezeichnung}
                </div>
            </div>);
        }
        return rows;
    }

    render() {
        let rows = this.getRows();
        return <div style={{textAlign:"left"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>- Hier können Mannschaften verwaltet werden.</div>
                <div>- Die Mannschaften werden zur Anlage von Spieltagen benötigt.</div>
                <div>- Spieler können einer Mannschaft zugewiesen werden.</div>
            </Fieldset>
            <Panel header="Mannschaften verwalten" style={{textAlign: "left"}}>
                <div>
                    {this.getEditArea()}
                </div>
                <div style={{marginTop:20}}>
                    {rows}
                </div>
            </Panel>
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteDialog: false,
                               id: undefined,
                               bezeichnung: "",
                           })}
                           callBackOnJa={() => this.loescheTeam()}
                           header="Mannschaft wirklich löschen"
                           textOben="Die Mannschaft kann Spieltagen u.v.m. zugewiesen sein, in diesem Fall ist das Löschen nicht empfohlen!!!"
                           textUnten="Möchtest du die Mannschaft wirklich löschen?"/>
        </div>
    }
}

SettingsTeams.propTypes = {
};

export default SettingsTeams;