import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {GameFirstToLegsComponent} from "../../general/GameFirstToLegsComponent";
import {GameModusComponent} from "../../general/GameModusComponent";
import {GameStartpunkteComponent} from "../../general/GameStartpunkteComponent";
import {SelectTeamComponent} from "../../general/SelectTeamComponent";
import DHDialog from "../../general/DHDialog";
import DHDatePicker from "../../general/DHDatePicker";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

const INITIAL_STATE = {
    id: "",
    date: "",
    beschreibung: "",
    teamid: undefined,
    firstToLegs: undefined,
    modusid: undefined,
    startpunkte: undefined,
};

class SpieltagEditDialog extends React.Component {

    constructor(props) {
        super(props);

        let id = this.props.spieltag !== undefined ? this.props.spieltag.id : undefined;
        this.state = {...INITIAL_STATE, id: id};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.ladeDaten();
        }
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        let id = this.state.id;
        if (id !== undefined && id !== null) {
            let url = ConfigUtil.getConfig().resourceUrls.gameday + "/" + id;
            FetchUtil.fetchGet(url,
                json => {
                    let mydate = Date.parse(json.date);
                    let newDate = new Date(mydate);

                    this.setState({id:json.id, date: newDate, beschreibung:json.beschreibung, teamid:json.teamid, firstToLegs: json.firstToLegs, modusid: json.modusid, startpunkte:json.startpunkte})
                },
                responseNotOk => {
                    this.showError("Fehler beim Laden der Trainingssession: " + responseNotOk.message);
                },
                error => {
                    this.showError("Fehler beim Laden der Trainingssession: " + error.message);
                })
        }
    }

    save() {
        this.showError(undefined);

        let id = this.state.id;
        let beschreibung = this.state.beschreibung;
        let teamid = this.state.teamid;

        let firstToLegs = this.state.firstToLegs;
        let modusid = this.state.modusid;
        let startpunkte = this.state.startpunkte;

        let data = {id: id, date: this.state.date, beschreibung:beschreibung, teamid: teamid, firstToLegs: firstToLegs, modusid:modusid, startpunkte:startpunkte};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.gameday,
            data,
            id => {
                this.setState({id: id});
                this.showInfo("Spieltag gespeichert.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    hideDialog() {
        this.setState(INITIAL_STATE);
        this.props.callBackEditDialogClose();
    }

    render() {
        let saveButtonDisabled = !this.state.date || !this.state.beschreibung|| !this.state.teamid || !this.state.firstToLegs;
        const dialogFooter = (
            <div>
                <Button label="Speichern" icon="pi pi-save" disabled={saveButtonDisabled} onClick={() => this.save()} />
                <Button label="Neuen Spieltag anlegen" disabled={!this.state.id} onClick={() => this.setState(INITIAL_STATE)} style={{width: "300px", marginTop:"5px"}}/>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.hideDialog()} className="p-button-secondary" />
            </div>
        );

        return <DHDialog buttons={dialogFooter} onClose={() => this.hideDialog()} title="Spieltag anlegen / bearbeiten" visible={this.props.visible} comp={<>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <div style={{height:300}}>
                <div className="grid" style={{marginTop:10}}>
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Datum:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <DHDatePicker disabled={false} value={this.state.date} changeValue={(date) => this.setState({date: date})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Beschreibung:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <InputText name="beschreibung" style={{width: 300}}
                                   value={this.state.beschreibung}
                                   onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Mannschaft:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <SelectTeamComponent teamid={this.state.teamid}
                                             onError={(messsage) => showError(messsage)}
                                             onChange={(teamid) => this.setState({teamid: teamid})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Legs zum Sieg:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameFirstToLegsComponent firstToLegs={this.state.firstToLegs} onChange={(firstToLegs) => this.setState({firstToLegs: firstToLegs})}/>
                    </div>
                </div>
                <div className="grid" style={{marginTop:20, marginBottom:10}}>
                    <div className="col" style={{textAlign: "left", fontWeight:"bold"}}>Optionale Eingaben (nur sobald über Darthelfer gescored werden soll)</div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Modus:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameModusComponent modusId={this.state.modusid} onChange={(modusid) => this.setState({modusid: modusid})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: 150, textAlign: "right"}}>Startpunkte:</div>
                    <div className="col" style={{textAlign: "left"}}>
                        <GameStartpunkteComponent startpunkte={this.state.startpunkte} onChange={(startpunkte) => this.setState({startpunkte: startpunkte})}/>
                    </div>
                </div>
            </div>
            </>}/>
    }
}

SpieltagEditDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    spieltag: PropTypes.object,
    callBackEditDialogClose: PropTypes.func.isRequired,
};

export default SpieltagEditDialog;
