import React from 'react'
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import DHDatePicker from "../../general/DHDatePicker";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";


const INITIAL_STATE = {
    id: "",
    name: "",
    firstname: "",
    nickname: "",
    email: "",
    mobilenumber: "",
    street: "",
    postcode: "",
    city: "",
    birthday: undefined,
    username: "",
};


class PlayerEditDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.ladeDaten = this.ladeDaten.bind(this);
        this.showError = this.showError.bind(this);
        this.showError = this.showError.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.player !== this.props.player) {
            this.ladeDaten();
        }
    }

    componentDidMount() {
        this.ladeDaten();
    }

    ladeDaten() {
        if (this.props.player === undefined || this.props.player === null) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.player + "/" + this.props.player.id;
        FetchUtil.fetchGet(url,
            json => {
                let geburtstag = undefined;
                if (json.birthday !== undefined && json.birthday !== null) {
                    let mydate = Date.parse(json.birthday);
                    geburtstag = new Date(mydate);
                }
                this.setState({...json, birthday: geburtstag});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden des Spielers: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden des Spielers: " + error.message);
            })
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    save() {
        this.showError(undefined);
        let id = this.state.id;
        let name = this.state.name;
        let firstname = this.state.firstname;
        let nickname = this.state.nickname;
        let email = this.state.email;
        let mobilenumber = this.state.mobilenumber;
        let street = this.state.street;
        let postcode = this.state.postcode;
        let city = this.state.city;
        let birthday = this.state.birthday;

        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.player,
            {id, name, firstname, nickname, email, mobilenumber, street, postcode, city, birthday},
            id => {
                this.setState({id: id});
                this.showInfo("Spieler wurde erfolgreich gespeichert.");
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError(responseNotOk.message);
            },
            error => {
                this.showError(error.message);
            }
        );
    }

    hideDialog() {
        this.setState(INITIAL_STATE);
        this.props.callBackEditDialogClose();
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Speichern" icon="pi pi-save" disabled={!this.state.name} onClick={() => this.save()}/>
                <Button label="Neuen Spieler anlegen" disabled={!this.state.id}
                        onClick={() => this.setState(INITIAL_STATE)}
                        style={{width: "200px", marginLeft: "5px", marginTop: "5px"}}/>
                <Button label="Abbrechen" icon="pi pi-times" onClick={() => this.hideDialog()}
                        className="p-button-secondary"/>
            </div>
        );

        return <DHDialog title="Person anlegen/bearbeiten"  buttons={dialogFooter} onClose={() => this.hideDialog()} comp={<>
            <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
                <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                    onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                    onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                    onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Name:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}>
                        <InputText name="name" className={!this.state.name ? "p-error" : ""} style={{width: "250px"}}
                                   value={this.state.name} onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Vorname:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="firstname"
                                                                                                     style={{width: "250px"}}
                                                                                                     value={this.state.firstname}
                                                                                                     onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Spitzname:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="nickname"
                                                                                                     style={{width: "250px"}}
                                                                                                     value={this.state.nickname}
                                                                                                     onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Strasse:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="street"
                                                                                                     style={{width: "250px"}}
                                                                                                     value={this.state.street}
                                                                                                     onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>PLZ/Ort:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}>
                        <InputText name="postcode"
                                   style={{width: "60px"}}
                                   value={this.state.postcode}
                                   onChange={(e) => this.handleChange(e)}/>
                        <InputText name="city"
                                   style={{width: "190px"}}
                                   value={this.state.city}
                                   onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Mobiltel.:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="mobilenumber"
                                                                                                     style={{width: "250px"}}
                                                                                                     value={this.state.mobilenumber}
                                                                                                     onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Geburtstag:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}>
                        <DHDatePicker disabled={false} value={this.state.birthday} changeValue={(date) => this.setState({birthday: date})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Email:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}><InputText name="email"
                                                                                                     style={{width: "250px"}}
                                                                                                     value={this.state.email}
                                                                                                     onChange={(e) => this.handleChange(e)}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Benutzername:</div>
                    <div className="col" style={{textAlign: "left", marginLeft: "5px"}}>{this.state.username}</div>
                </div>
            </div>
        </>}/>
    }
}

PlayerEditDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    callBackEditDialogClose: PropTypes.func.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    player: PropTypes.object,
};

export default PlayerEditDialog;