import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import FunGamesArea from "./FunGamesArea";
import KennwortAendernArea from "./KennwortAendernArea";
import ForumUserAnlegenArea from "./ForumUserAnlegenArea";
import PropTypes from "prop-types";
import ProfilAllgemeineEinstellungenArea from "./ProfilAllgemeineEinstellungenArea";
import DeineDatenAendern from "./DeineDatenAendern";
import DoppelpartnerArea from "./DoppelpartnerArea";
import ProfilLigenArea from "./ProfilLigenArea";
import ProfilTurnierArea from "./ProfilTurnierArea";
import ProfilAutodartsArea from "./ProfilAutodartsArea";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class Profil extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    ladeDaten() {
        this.showError(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.profil + "/" + this.props.userId;

        FetchUtil.fetchPost(url,
            {},
            json => {
                this.setState({profil: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Daten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Daten: " + error.message);
            }
        );
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <div className="pageHeader" style={{backgroundColor: "#ff99c2"}}>
                Profil
            </div>
            {this.state.profil === null || this.state.profil === undefined ?
            "Daten werden geladen":
            this.showProfil()
            }
        </div>
    }

    showProfil() {
        return  <><div className="col-12 md:col-12 lg:col-12" style={{padding: 10}}>
            <div className="ligashortoverview">
                <span style={{marginRight: 30, fontWeight: "bold", fontSize: 25}}>Dein Username: {this.state.profil.username}</span>
            </div>
        </div>
        <div style={{padding:10}}>
            <div className="grid">
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <DeineDatenAendern callbackRefresh={this.ladeDaten} profil={this.state.profil}/>
                </div>
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <KennwortAendernArea onLogout={this.props.logout}/>
                </div>
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <ProfilAllgemeineEinstellungenArea callbackRefresh={this.ladeDaten}
                                                       sendEventMails={this.state.profil.sendEventMails}
                                                       showMeetingkey={this.state.profil.showMeetingkey}
                                                       newsletter={this.state.profil.newsletter}
                                                       showScoringDoubleQuestion={this.state.profil.showScoringDoubleQuestion}
                                                       scoringQuestionOffSetting={this.state.profil.scoringQuestionOffSetting}/>
                </div>
                {this.getAnstehendeSpieleArea()}
                <ProfilLigenArea ligenAktuell={this.state.profil.ligenAktuell} ligenAbgeschlossen={this.state.profil.ligenAbgeschlossen}/>
                <ProfilTurnierArea turniereAktuell={this.state.profil.turniereAktuell} turniereAbgeschlossen={this.state.profil.turniereAbgeschlossen}/>
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <ProfilAutodartsArea propBoardId={this.state.profil.autodartsBoardId} onSave={(boardId) =>
                        this.props.saveAutodartsBoardId(boardId)}/>
                </div>
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <ForumUserAnlegenArea username={this.state.profil.username} userId={this.props.userId}
                                          helpdeskId={this.state.profil.helpdeskId} helpdeskRequested={this.state.profil.helpdeskRequested}/>
                </div>
                <div className="col-12 md:col-12 lg:col-4" style={{padding: 10}}>
                    <DoppelpartnerArea userId={this.props.userId}/>
                </div>
            </div>
        </div>
        </>;
    }

    getAnstehendeSpieleArea() {
        return <div className="col-12 md:col-12 lg:col-4" style={{padding: 10, textAlign: "left"}}>
            <FunGamesArea funGames={this.state.profil.funGameDtos} userId={this.props.userId}
                          showDelete={true} callBackAfterDelete={() => this.ladeDaten()}/>
        </div>
    }
}

Profil.propTypes = {
    userId: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    saveAutodartsBoardId: PropTypes.func.isRequired,
};

export default Profil
