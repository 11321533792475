import React from 'react'
import PropTypes from "prop-types";
import ConfigUtil from "../util/ConfigUtil";
import FetchUtil from "../util/FetchUtil";
import ErrorInfoComponent from "./general/ErrorInfoComponent";

class RegistrationComplete extends React.Component {

    constructor(props) {
        super(props);

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    componentDidMount() {
        this.registrierungAbschliessen();
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    registrierungAbschliessen() {
        let userregistrationid = this.props.userregistrationid;
        if (userregistrationid === null || userregistrationid === undefined || userregistrationid === "") {
            this.showError("Aufruf dieser Seite ohne eine Registrierungs-ID ist nicht erlaubt.");
            return;
        }

        let url = ConfigUtil.getConfig().resourceUrls.user + "/registrationcomplete?userregistrationid=" + userregistrationid;
        FetchUtil.fetchPost(url,
            {},
            message => {
                if( message === "") {
                    this.showInfo("Registrierung ist abgeschlossen, du kannst dich jetzt anmelden.");
                } else {
                    this.showError(message);
                }
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error);
            }
        );
    }

    render() {
        return <div style={{backgroundColor: "white", color: "black", textAlign:"center", marginTop:50, height:300, fontSize:25}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <a href={this.props.startseiteUrl}><img alt="Logo" src={"/images/darthelfer.svg"} style={{width: "100%", maxWidth: "100px"}}/>
            <br/>Hier gehts zur Startseite...
            </a>
        </div>
    }
}

RegistrationComplete.propTypes = {
    userregistrationid: PropTypes.string.isRequired,
};

export default RegistrationComplete;
