import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {ListBox} from "primereact/listbox";
import StatistikGameComponent from "./StatistikGameComponent";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

export default function StatistikGamesDialog({tournamentId, playerId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    let [games, setGames] = useState([]);
    let [selectedListGame, setSelectedListGame] = useState(undefined);

    useEffect(() => {
        ladeGames();
    }, []);

    const ladeGames = () => {
        let url = ConfigUtil.getConfig().resourceUrls.public + "/tournamentgames/" + tournamentId + "/" + playerId;
        FetchUtil.fetchGet(url,
            json => {
                setGames(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden der Games: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden der Games: " + error.message);
            });
    }

    const getGameRowsFuerListe = () => {
        const image = <img src="/images/dot_green.png" style={{width:15, height:15, marginRight:5, marginLeft:5}} alt="Gewinner"/>
        let itemsGruppenphase = []
        let itemsKO = []
        for (const game of games) {
            let item = {label:
                    <div className="grid">
                        <div className="col-fixed" style={{width:50}}>
                            {game.spieler1Id === game.gewonnenPlayerId && image}
                        </div>
                        <div className="col" style={{textAlign:"center"}}>
                            {game.spieler1Name} vs. {game.spieler2Name}
                        </div>
                        <div className="col-fixed" style={{width:50}}>
                            {game.spieler2Id === game.gewonnenPlayerId && image}
                        </div>
                    </div>,
                value: game.id};
            if( game.gruppenphase) {
                itemsGruppenphase.push(item)
            } else {
                itemsKO.push(item)
            }
        }
        let rows = [];
        if( itemsGruppenphase.length > 0 ) {
            rows.push({ label: 'Gruppenphase', code: 'group',items: itemsGruppenphase});
        }
        if( itemsGruppenphase.length === 0 && itemsKO.length > 0 ) {
            // keine gruppenphase vorhanden, titel allgemein halten.
            rows.push({ label: 'Spiele', code: 'ko',items: itemsKO});
        }
        if( itemsGruppenphase.length !== 0 && itemsKO.length > 0 ) {
            // es wurde eine Gruppenphase vor dem KO gespielt
            rows.push({ label: 'KO/DKO-Phase', code: 'ko',items: itemsKO});
        }
        return rows;
    }

    return <Sidebar showCloseIcon={false} visible={true} fullScreen onHide={() => onClose()}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{position: "absolute", top:5, left:5, zIndex:1000}}><Button onClick={() => onClose()} label="Bereich schliessen"/></div>
        <div style={{padding:10, marginTop:30, marginBottom:20, width:"100%", backgroundColor:"#ac1c27", textAlign:"center", color:"white", fontSize:25}}>Games/Details</div>
        <div style={{width:"100%"}}>
            <ListBox value={selectedListGame} options={getGameRowsFuerListe()} onChange={(e) => {
                setSelectedListGame(e.value);
            }}
                     optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" />
            {selectedListGame !== undefined && selectedListGame !== null &&
                <StatistikGameComponent gameId={selectedListGame}/>
            }
        </div>
    </Sidebar>
}
