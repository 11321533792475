import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import TournamentTable from "../tournament/TournamentTable";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";
import TurnierVerwaltenDialog from "../tournament/TurnierVerwaltenDialog";
import LigaVerwaltenDialog from "../tournament/LigaVerwaltenDialog";
import TournamentCopySidebar from "../public/tournaments/TournamentCopySidebar";
import LigaspieltagVerwaltenDialog from "../tournament/LigaspieltagVerwaltenDialog";
import LigaAktionenComponent from "../tournament/tabs/LigaAktionenComponent";
import DHButtonDefault from "../../general/DHButtonDefault";
import DHTextFieldSearch from "../../general/DHTextFieldSearch";

function VeranstalterTournamentOverview({eventOperatorId, userId, counter}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [filterTournamentText, setFilterTournamentText] = React.useState("");

    let [showNewEvents, setShowNewEvent] = useState(true);
    let [showStartedEvents, setShowStartedEvent] = useState(true);
    let [showClosedEvents, setShowClosedEvent] = useState(false);

    let [showLigaspieltagDialog, setShowLigaspieltagDialog] = useState(false);
    let [showLigaDialog, setShowLigaDialog] = useState(false);
    let [showKODKODialog, setShowKODKODialog] = useState(false);
    let [showTurnierKopierenFrage, setShowTurnierKopierenFrage] = useState(false);
    let [showAktionen, setShowAktionen] = useState(false);
    let [selectedTournament, setSelectedTournament] = useState(undefined);

    let [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        ladeDaten();
    }, [eventOperatorId, counter]);

    const ladeDaten = () => {
        if( eventOperatorId === undefined) {
            setTournaments([]);
            return;
        }
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.tournament + "/byEventOperator/" + eventOperatorId,
            json => {
                setTournaments(json.all);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
            },
            error => {
                setErrorMessage(error.message);
            });
    }

    const callBackTurnierBearbeiten = (tournament) => {
        setSelectedTournament(tournament);
        if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id) {
            setShowLigaspieltagDialog(true);
        } else if( tournament.turniersystem === TOURNAMENTSYSTEMS.LIGA.id) {
            setShowLigaDialog(true);
        } else if (tournament.turniersystem === TOURNAMENTSYSTEMS.ROUND_ROBIN.id || tournament.turniersystem === TOURNAMENTSYSTEMS.DOPPELTERKO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.EINFACHERKO.id ||
            tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_KO.id || tournament.turniersystem === TOURNAMENTSYSTEMS.GRUPPE_DANN_DKO.id
            || tournament.turniersystem === TOURNAMENTSYSTEMS.KRATZER.id || tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id
            || tournament.turniersystem === TOURNAMENTSYSTEMS.ANY_GAMES.id) {
            setShowKODKODialog(true);
        } else {
            setErrorMessage("Fehlerhafte Turnierform. Admin verständigen.");
        }
    }

    const callBackTurnierKopieren = (tournament) => {
        setSelectedTournament(tournament);
        setShowTurnierKopierenFrage(true);
    }

    const callBackShowAktionen = (tournament) => {
        setSelectedTournament(tournament);
        setShowAktionen(true);
    }

    const getFiltertTournaments = () => {
        let newTournaments = [];
        for (const tournament of tournaments) {
            let bezeichnungIncludes = tournament.bezeichnung.toUpperCase().includes(filterTournamentText.toUpperCase());
            if( filterTournamentText !== "" && !bezeichnungIncludes ) {
                continue;
            }
            if( tournament.tournamentstatus === "gestartet" && showStartedEvents) {
                newTournaments.push(tournament);
            } else if( tournament.tournamentstatus === "abgeschlossen" && showClosedEvents) {
                newTournaments.push(tournament);
            } else if( tournament.tournamentstatus === "neu" && showNewEvents) {
                newTournaments.push(tournament);
            }
        }
        if( !showClosedEvents ) {
            return newTournaments.reverse();
        } else {
            return newTournaments;
        }
    }

    return <div className="col-12 md:col-12 lg:col-10" style={{padding: 10}}>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div className="ligashortoverview" style={{height: "100%", borderLeft: "10px solid #0497ae"}}>
            <div className="grid">
                <div className="col"><h3>Eventübersicht</h3></div>
            </div>
            <div style={{marginBottom:10}}>Anzahl Events: {tournaments.length}</div>
            <div style={{marginBottom:10}}>
                <DHButtonDefault label="Neu"
                                 onClick={() => {
                                     if( showNewEvents) {
                                         setShowNewEvent(false);
                                     } else {
                                         setShowNewEvent(true);
                                         setShowClosedEvent(false);
                                     }}
                                 }
                                 active={showNewEvents}
                                 style={{marginTop:5}}/>
                <DHButtonDefault label="Gestartet"
                                 onClick={() => {
                                     if( showStartedEvents) {
                                         setShowStartedEvent(false);
                                     } else {
                                         setShowStartedEvent(true);
                                         setShowClosedEvent(false);
                                     }}
                                 }
                                 active={showStartedEvents}
                                 style={{marginTop:5}}/>
                <DHButtonDefault label="Abgeschlossen"
                                 onClick={() => {
                                     if( !showClosedEvents) {
                                         setShowClosedEvent(true);
                                         setShowNewEvent(false);
                                         setShowStartedEvent(false);
                                     } else {
                                         setShowClosedEvent(false);
                                         setShowNewEvent(true);
                                         setShowStartedEvent(true);
                                     }}
                                 }
                                 active={showClosedEvents}
                                 style={{marginTop:5}}/>
                <span style={{marginLeft:10}}>
                    <DHTextFieldSearch id="id-filter" label="Suche" value={filterTournamentText} onChange={(text) => setFilterTournamentText(text)} width={120}/>
                </span>
            </div>
            <div>
                <TournamentTable tournaments={getFiltertTournaments()} showButtons={true} onTournamentCopy={callBackTurnierKopieren}
                                 showOnline={true} showSteeldarts={true} showEDart={true}
                                 onTournamentEdit={callBackTurnierBearbeiten} onShowAktionen={callBackShowAktionen}/>
            </div>
        </div>
        {showLigaspieltagDialog && <LigaspieltagVerwaltenDialog tournamentId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                    callBackRefresh={ladeDaten}
                                                    callBackOnDelete={() => {
                                                        ladeDaten();
                                                        setShowLigaspieltagDialog(false);
                                                        setSelectedTournament(false);
                                                    }}
                                                    callBackOnClose={() => {
                                                        setShowLigaspieltagDialog(false);
                                                        setSelectedTournament(false);
                                                    }}/>
        }
        {showKODKODialog && <TurnierVerwaltenDialog tournamentId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                    callBackRefresh={ladeDaten}
                                                    callBackOnDelete={() => {
                                                        ladeDaten();
                                                        setShowKODKODialog(false);
                                                        setShowLigaDialog(false);
                                                        setSelectedTournament(false);
                                                    }}
                                                    callBackOnClose={() => {
                                                        setShowKODKODialog(false);
                                                        setShowLigaDialog(false);
                                                        setSelectedTournament(false);
                                                    }}/>
        }
        {showLigaDialog && <LigaVerwaltenDialog ligaId={selectedTournament !== undefined ? selectedTournament.id : null}
                                                           callBackRefresh={ladeDaten}
                                                           callBackOnClose={() => {
                                                               setShowLigaDialog(false);
                                                               setSelectedTournament(undefined);
                                                           }}/>
        }
        {showAktionen && <LigaAktionenComponent showAsSidebar={true} onError={(message) => setErrorMessage(message)}
                                                           onInfo={(message) => setInfoMessage(message)}
                                                           onClearMessages={() => setErrorMessage(undefined)}
                                                           callBackRefresh={() => ladeDaten()}
                                                           callBackCloseDialog={() => setShowAktionen(false)}
                                                           tournamentId={selectedTournament.id}
                                                            userId={userId}
                                                           onClose={() => setShowAktionen(false)}
                                                           callBackOnDelete={() => {
                                                               ladeDaten();
                                                               setShowAktionen(false);
                                                           }}
                                                           callBackLoadNewTournament={() => ladeDaten()}/>
        }
        {showTurnierKopierenFrage &&
            <TournamentCopySidebar
                tournamentId={selectedTournament.id}
                turniersystem={selectedTournament.turniersystem}
                propMannschaftHeim={selectedTournament.mannschaftHeim}
                propBezeichnung={selectedTournament.bezeichnung}
                propBezeichnungKurz={selectedTournament.bezeichnungShort}
                onCopy={() => ladeDaten()}
                onClose={() => {
                    setShowTurnierKopierenFrage(false);
                    setSelectedTournament(undefined);
                    }}
            />
        }

    </div>
}

export default VeranstalterTournamentOverview;
