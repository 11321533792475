import React from 'react'
import ConfirmDialog from "../../general/ConfirmDialog";
import {Button} from "primereact/button";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {InputText} from "primereact/inputtext";
import UserSearch from "./UserSearch";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class AutomatView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {selectedTokenId: undefined, beschreibung: "", tokens: [],
            user: undefined,
            showGenerateTokenDialog:false, showDeleteTokenDialog: false, showActivateTokenDialog: false, showShareDialog: false};

        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.ladeDaten = this.ladeDaten.bind(this);
        this.generateToken = this.generateToken.bind(this);
        this.deleteToken = this.deleteToken.bind(this);
        this.activateToken = this.activateToken.bind(this);
    }

    componentDidMount() {
        this.ladeDaten();
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    showWarn(message) {
        this.setState({warnMessage: message});
    }

    ladeDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.automat + "/tokens",
            json => {
                this.setState({tokens: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    shareToken() {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.automat + "/share/" + this.state.selectedTokenId + "/" + this.state.user.id,
            {},
            json => {
                this.setState({selectedTokenId:undefined, showShareDialog: false, user: undefined});
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({selectedTokenId:undefined, showShareDialog: false, user: undefined});
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({selectedTokenId:undefined, showShareDialog: false, user: undefined});
                this.showError("Fehler: " + error.message);
            }
        );
    }

    activateToken() {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.automat + "/activate/" + this.state.selectedTokenId,
            {},
            json => {
                this.setState({selectedTokenId:undefined, showActivateTokenDialog: false});
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({selectedTokenId:undefined, showActivateTokenDialog: false});
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({selectedTokenId:undefined, showActivateTokenDialog: false});
                this.showError("Fehler: " + error.message);
            }
        );
    }

    generateToken() {
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.automat,
            {bezeichnung: this.state.beschreibung},
            json => {
                this.setState({showGenerateTokenDialog:false, beschreibung: ""});
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({showGenerateTokenDialog:false});
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({showGenerateTokenDialog:false});
                this.showError("Fehler: " + error.message);
            }
        );
    }

    deleteToken() {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.automat + "/" + this.state.selectedTokenId,
            {},
            json => {
                this.setState({selectedTokenId: undefined, showDeleteTokenDialog:false});
                this.ladeDaten()
            },
            responseNotOk => {
                this.setState({selectedTokenId: undefined, showDeleteTokenDialog:false});
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.setState({selectedTokenId: undefined, showDeleteTokenDialog:false});
                this.showError("Fehler: " + error.message);
            }
        );
    }

    getDescription(description) {
        return <div className="grid" style={{textAlign:"left"}}>
            <div className="col" style={{fontSize:12}}>
                {description}
            </div>
        </div>
    }

    getShareDialog() {
        if( !this.state.showShareDialog ) {
            return "";
        }

        return <DHDialog onClose={() => this.setState({showShareDialog: false})} title="Share Token" show={this.state.showShareDialog}
                         showCloseButton={true}
                         comp={<>
            {this.state.shareusermessage && <div style={{marginBottom: 10, color:"blue", fontWeight:"bold"}}>
                {this.state.shareusermessage}
            </div>
            }
            <div style={{marginBottom:10}}>
                Möchtest du deinen Token mit einem andern teilen?
            </div>
            <div style={{marginBottom:20}}>
                Wähle den User anhand des Usernamens aus und klicke teilen.
            </div>
            <div>
                <UserSearch callBackError={(message) => {
                    this.showError(message);
                    this.setState({user:undefined, showShareDialog:false});
                }}
                            callBackClearMessages={() => this.showError(undefined)}
                            callBackInfo={(message) => this.setState({shareusermessage: message})}
                            callBackUserFound={(user) => this.setState({user:user, shareusermessage:undefined})}/>
            </div>
            <div>
                <Button icon="pi pi-check"
                        label="Token teilen"
                        tooltip="Token teilen"
                        disabled={this.state.user === undefined}
                        onClick={() => this.shareToken()}/>

            </div>
        </>}/>
    }
    getRows() {
        if( this.state.tokens === null || this.state.tokens === undefined) {
            return [];
        }
        let rows = [];
        for (const token of this.state.tokens) {
            let isUserOwner = this.props.userId === token.ownerUserId;
            let isActive = token.active;
            rows.push(<div style={{marginBottom:20}} key={"key_token_list_" + token.id}>
                <div className="grid">
                    <div className="col" style={{fontStyle:"bold"}}>
                        {!isUserOwner && <img src="/images/share.png" style={{marginRight:10, height:30}} alt="Shared Token" name="Shared Token"/>}
                        {isActive && <img src="/images/check2.png" style={{marginRight:10, height:30}} alt="Aktiviertes Token" name="Aktiviertes Token"/>}
                        Token: {token.schluessel}
                        <Button icon="pi pi-check"
                                onClick={() => this.setState({selectedTokenId: token.id, showActivateTokenDialog:true})}
                                disabled={isActive}
                                style={{marginLeft: 10}}
                                tooltip={"Token aktivieren"}/>
                        <Button icon="pi pi-trash"
                                onClick={() => this.setState({selectedTokenId: token.id, showDeleteTokenDialog:true})}
                                style={{marginLeft: 10}}
                                tooltip={"Token löschen"}/>
                        {isUserOwner &&
                            <Button icon="pi pi-cloud-upload"
                                    onClick={() => this.setState({selectedTokenId: token.id, showShareDialog:true})}
                                    style={{marginLeft: 10}}
                                    tooltip={"Share Token"}/>
                        }
                    <br/>
                    {token.bezeichnung}</div>
                </div>
                <hr/>
                </div>
                );
        }
        return rows;
    }

    render() {
        let rows = this.getRows();
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <div className="pageHeader" style={{backgroundColor: "#b3ecff"}}>
                Automat
            </div>
            <div style={{padding:10, textAlign:"left", alignContent:"left"}}>
                {this.getDescription("In folgendem Bereich kannst du Tokens für die Anbindung eines E Dart Automaten verwalten, über diesen Token wirst du dich mit Darthelfer verbinden können und deine Würfe automatisch an Darthelfer übertragen können.")}
                {this.getDescription("Details findest du unter support.darthelfer.de.")}
                <br/>
                {this.getDescription("Du kannst deinen Token mit anderen sharen. Das bedeutet, dass z.B. dein Kumpel auf deinem Automaten ebenfalls mit Darthelfer spielen kann.")}
                {this.getDescription("Bitte denke daran, dass der entsprechende Token, der am Controller eingetragen ist, aktiviert sein muss.")}
                {this.getDescription(<span>Folgendes Icon <img src="/images/share.png" style={{marginLeft:10, marginRight:10, height:30}} alt="Shared Token" name="Shared Token"/> zeigt dir, dass eine andere Person den Token mit dir teilt. Er bleibt der Eigentümer, du kannst den Token aber ebenfalls nutzen.</span>)}
                {this.getDescription(<span>Du kannst immer nur einen Token aktivieren. Sofern du an einem anderen Automaten mit einem Token spielst, musst du diesen hier vorher aktivieren. Dieses Icon <img src="/images/check2.png" style={{marginLeft:10, marginRight:10, height:30}} alt="Aktiviertes Token" name="Aktiviertes Token"/> zeigt dir einen eventuell aktiven Token. </span>)}

                <div className="grid" style={{marginTop:10}}>
                    <div className="col">
                        Beschreibung Token:
                        <InputText style={{width:"100%"}} value={this.state.beschreibung} maxLength={250}
                                   onChange={(e) => this.setState({beschreibung:e.target.value})}/>
                    </div>
                </div>
                <div className="grid">
                    <div className="col">
                        <Button icon="pi pi-check"
                                label="Generiere neuen Token"
                                tooltip="Generiere neuen Token"
                                disabled={this.state.beschreibung === ""}
                                onClick={() => this.setState({showGenerateTokenDialog: true})}/>
                    </div>
                </div>
                <hr/>
                <div>
                    {rows}
                </div>
            </div>
            <div>
                {this.getShareDialog()}
            </div>
            <ConfirmDialog visible={this.state.showGenerateTokenDialog}
                           callBackOnNein={() => this.setState({showGenerateTokenDialog: false})}
                           callBackOnJa={this.generateToken}
                           header="Token generieren"
                           textOben="Bitte generiere nur einen Token, sobald du eine Anbindung deines E Dart Automaten an Darthelfer vornehmen wirst."
                           textUnten="Möchtest du wirklich einen Token generieren?"/>
            <ConfirmDialog visible={this.state.showDeleteTokenDialog}
                           callBackOnNein={() => this.setState({showDeleteTokenDialog: false})}
                           callBackOnJa={this.deleteToken}
                           header="Token löschen"
                           textOben="Du brauchsten den Token nicht mehr..."
                           textUnten="Möchtest du den Token wirklich löschen?"/>
            <ConfirmDialog visible={this.state.showActivateTokenDialog}
                           callBackOnNein={() => this.setState({showActivateTokenDialog: false})}
                           callBackOnJa={this.activateToken}
                           header="Token aktivieren"
                           textOben=""
                           textUnten="Möchtest du den Token wirklich aktivieren?"/>
        </div>
    }
}

AutomatView.propTypes = {
    userId: PropTypes.string.isRequired
};

export default AutomatView;
