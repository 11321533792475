import React from 'react';
import PropTypes from "prop-types";
import {Button} from "primereact/button";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../../../constants/navigationItems";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../../../util/ConfigUtil";
import AusbullenComponent from "../AusbullenComponent";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";

let stompClient = undefined;

class ScoringShortCricketView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gameid: props.gameid,
            saving: false,
            bullAbgeschickt: false,
            wuerfe: [],
        };

        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.onGameError = this.onGameError.bind(this);
        this.onChatMessageReceived = this.onChatMessageReceived.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.props.gameid);
    }

    componentWillUnmount() {
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
    }

    websocketConnect(gameid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                console.log("Websocket - connect success");
                const destination = "/topic/public/game/cricket/" + gameid;
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
                const destinationGameError = "/topic/public/game/error/" + this.props.playerid;
                console.log("subscribe to " + destinationGameError);
                stompClient.subscribe(destinationGameError, this.onGameError);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const json = JSON.parse(payload.body);
        let daten = {...json, saving: false, automatenwerte: undefined, wuerfe: []};
        if( daten.erneutBullen ) {
            daten = {...daten, bullAbgeschickt:false}
        }
        this.setState(daten);
    }

    onChatMessageReceived(payload) {
        const body = JSON.parse(payload.body);
        if( body.messages.length > 0 ) {
            this.setState({chatMessages: body.messages });
        }
    }

    onGameError(payload) {
        this.showError(payload.body);
        this.setState({bullAbgeschickt: false, saving: false});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    entferneLetztenWurf() {
        let wuerfe = this.state.wuerfe;
        let neueWuerfe = wuerfe.splice(0, wuerfe.length -1);
        this.setState({wuerfe: neueWuerfe});
    }

    score() {
        this.showError(undefined);
        if (this.state.saving) {
            return;
        }

        this.setState({saving: true});

        let url = "/app/score/cricket/" + this.state.gameid + "/" + this.props.playerid;
        stompClient.send(url, {}, JSON.stringify({zahlen: this.state.wuerfe}));
    }

    isSetModus() {
        return this.state.sets && this.state.sets > 1;
    }

    getPlayerScoreDtoByPlayers(players, playerid, opposite) {
        for (const player of players) {
            if (opposite && player.playerid !== playerid) {
                return player;
            }
            if (!opposite && player.playerid === playerid) {
                return player;
            }
        }
        return undefined;
    }

    getPlayerScoreDto(playerid, opposite) {
        return this.getPlayerScoreDtoByPlayers(this.state.players, playerid, opposite);
    }

    erzeugeZahlenButtons() {
        let buttons = [];
        buttons.push(this.getButton(0));
        for (const zahlTmp of this.state.zahlen) {
            buttons.push(this.getButton(zahlTmp));
        }
        return buttons;
    }

    erzeugeEingabeAnzeigenArea() {
        let columns = [];
        for (const wurf of this.state.wuerfe) {
            columns.push(<div className="col-fixed" style={{width:"30%", margin:5}}>
                {wurf}
                </div>
            );
        }
        return <div className="grid" style={{color:"black", fontSize:20, marginTop:20, height:50, textAlign:"center", backgroundColor:"#afabab", margin:5}}>
            {columns}
        </div>
    }

    existiertEinGewinner() {
        return this.state.gewonnenPlayerid !== null && this.state.gewonnenPlayerid !== undefined;
    }

    existiertUnentschieden() {
        return this.state.unentschieden !== null && this.state.unentschieden !== undefined && this.state.unentschieden;
    }

    mussAusgebulltWerden() {
        let player1idAktiv = this.getPlayerScoreDto(this.props.playerid, false).active;
        let player2idAktiv = this.getPlayerScoreDto(this.props.playerid, true).active;
        return !player1idAktiv && !player2idAktiv;
    }

    sendBullen(bull1, bull2, bull3) {
        this.showError(undefined);

        let wertGesamt = bull1 + ";" + bull2 + ";" + bull3;
        if( wertGesamt === null || wertGesamt === undefined) {
            // es gab Calls in das Backend, wo der Wert aus welchem Grund auch immer "null" war. Sicherheitsabfrage.
            this.showError("Fehler beim Bullen, bitte aktualisiere den Browser und gib dein Ergebnis erneut ein.");
            return;
        }
        this.setState({bullAbgeschickt: true});
        let url = "/app/bullen/" + this.state.gameid + "/" + this.props.playerid + "/" + wertGesamt;
        let data = JSON.stringify({wert: wertGesamt});
        stompClient.send(url, {}, data);
    }

    getPlayerArea(currentPlayer) {
        let player = this.getPlayerScoreDto(this.props.playerid, !currentPlayer);
        let setModus = this.isSetModus();
        let label = "";
        if( setModus) {
            label = "Sets: " + player.gewonneneSets + " - Legs: " + player.gewonneneLegs;
        } else {
            label = "Legs: " + player.gewonneneLegs;
        }

        let backgroundColorScore = player.active ? "#77b300" : "#0059b3";

        let isLegBeginner = !this.mussAusgebulltWerden() && this.state.beginnerLegPlayerid === player.playerid;
        return <div style={{padding: 20, backgroundColor: backgroundColorScore}}>
            <div className="grid">
                <div className="col"
                     style={{fontSize: 35, alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                    {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
                    {player.username !== null ? player.username : player.playername}
                    {isLegBeginner && <img src="/images/dart_single.png" alt="Legbeginner" style={{marginLeft: 20, height: 40, width: 40}}/>}
                </div>
            </div>
            <div className="grid">
                <div className="col"
                     style={{fontSize: 25, alignItems: "center", justifyContent: "center", textAlign: "center"}}>
                    Punkte: {player.punkte} / {label}
                </div>
            </div>
        </div>
    }

    getAreaAnzeigeart(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden) {
        return <>
            <div className="grid" style={{width: "100%", margin: 0, marginTop: 10, padding: 0}}>
                <div className="col-12 md:col-12 lg:col-12" style={{color: "white"}}>
                    {playerAktiv && !unentschiedenVorhanden && !gewinnerVorhanden && <>
                        <div className="card" style={{padding: 10, fontSize: 20, color: "black", backgroundColor:"#afabab",}}>
                            {this.erzeugeEingabeAnzeigenArea()}
                            <div className="grid" style={{width:"100%", height:70}}>
                                <div className="col" style={{backgroundColor:"#77b300", color:"white", cursor:"pointer", margin:5}}
                                     onClick={() => this.score()}>
                                    Absenden
                                </div>
                                {this.state.wuerfe.length === 0 && <div className="col-fixed" style={{width: 50}}></div>}
                                {this.state.wuerfe.length > 0 &&
                                    <div className="col-fixed" style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        cursor: "pointer",
                                        width: 50, margin:5
                                    }} onClick={() => this.entferneLetztenWurf()}>
                                        <i className="pi pi-step-backward" style={{'fontSize': '1em', cursor: "pointer"}}/>
                                    </div>
                                }
                            </div>
                            <>
                                {this.erzeugeZahlenButtons()}
                            </>
                        </div>
                    </>
                    }
                    {!playerAktiv && <div style={{color:"gray"}}>Du bist aktuell nicht an der Reihe!!!</div>}
                </div>
            </div>
        </>
    }

    starteFolgeGame() {
        this.setState({gameId: this.state.folgeGameId, bullabgeschickt:false});
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
        this.websocketConnect(this.state.folgeGameId);
    }

    render() {
        if( this.state.timestampAsString === undefined ) {
            return "Lade Game...";
        }
        let currentPlayer = this.getPlayerScoreDto(this.props.playerid, false);
        let playerAktiv = currentPlayer.active;
        let gewinnerVorhanden = this.existiertEinGewinner();
        let unentschiedenVorhanden = this.existiertUnentschieden();
        return <div
            style={{backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            {this.state.showShortCuts && this.getShortCutArea()}
            {this.mussAusgebulltWerden() && <AusbullenComponent gameid={this.props.gameid}
                                                                player={this.getPlayerScoreDto(this.props.playerid, false)}
                                                                gegner={this.getPlayerScoreDto(this.props.playerid, true)}
                                                                callBackOnError={(message) => this.showError(message)}
                                                                callBackClearErrorMessages={() => this.showError(undefined)}
                                                                callBackSendBullen={(bull1, bull2, bull3) => this.sendBullen(bull1, bull2, bull3)}
                                                                erneutBullen={this.state.erneutBullen}
                                                                bullAbgeschickt={this.state.bullAbgeschickt}/>
            }
            {this.getAreaAnzeigeart(playerAktiv, unentschiedenVorhanden, gewinnerVorhanden)}
            {unentschiedenVorhanden && <div className="grid" style={{height:"100%", width:"100%", fontSize:15, color:"#77b300", fontWeight:"bold", marginTop:20, padding:20}}>
                <div className="col">
                    Das Spiel endet unentschieden!
                </div>
            </div>
            }
            {gewinnerVorhanden && <div className="grid" style={{height:"100%", width:"100%", fontSize:15, color:"#77b300", fontWeight:"bold", marginTop:20, padding:20}}>
                <div className="col">
                    Spieler {this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername} hat gewonnen!
                    {this.state.folgeGameId !== undefined && this.state.folgeGameId !== null && <>
                        <br/>
                        Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                        <br/>
                        <br/>
                        <img src="/images/dart_select.png" alt="Spiel starten" style={{cursor:"pointer", width: 80, marginLeft:30}} onClick={() => this.starteFolgeGame()}/>
                        <br/>
                    </>
                    }
                </div>
            </div>
            }
            <div className="grid" style={{height: 60, margin: 0, marginBottom:5, padding: 0, width: "100%", backgroundColor: "#0059b3"}}>
                <div className="col" style={{width: 60, marginRight: 20}}>
                    <NavLink to={NAVIGATION_ITEM.START.route} style={{textDecoration: "none"}}>
                        <Button className="p-button-danger" icon="pi pi-power-off"
                                style={{height: 40, width: 40, marginTop: 5, padding: 0}}/>
                    </NavLink>
                </div>
            </div>
        </div>
    }

    addWurf(zahl) {
        let wuerfe = this.state.wuerfe;
        if( wuerfe.length >= 3 ) {
            return;
        }
        wuerfe.push(zahl);
        this.setState({wuerfe: wuerfe});
    }

    getButton(zahl) {
        if( zahl === 0 ) {
            return <div className="grid" style={{marginTop:5}}>
                    <div className="col-fixed" style={{width:"100%", height:50, backgroundColor:"#D6BA1A", textAlign:"center", cursor:"pointer"}}
                         onClick={() => this.addWurf("S" + zahl)}>
                        {zahl}
                    </div>
                </div>
        }
        return <div className="grid" style={{marginTop:5}}>
                <div className="col-fixed" style={{width:"33%", height:50, backgroundColor:"#D6BA1A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("S" + zahl)}>
                    {zahl}
                </div>
                <div className="col-fixed" style={{width:"33%", height:50, backgroundColor:"#D6931A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("D" + zahl)}>
                        D{zahl}
                </div>
                {zahl !== 25 && <div className="col-fixed" style={{width:"33%", height:50, backgroundColor:"#D6561A", textAlign:"center", cursor:"pointer"}}
                     onClick={() => this.addWurf("T" + zahl)}>
                    T{zahl}
                </div>
                }
            </div>
    }
}

ScoringShortCricketView.propTypes = {
    gameid: PropTypes.string.isRequired,
    playerid: PropTypes.string.isRequired,
};

export default ScoringShortCricketView;
