import React from 'react'
import PropTypes from "prop-types";

import {Panel} from 'primereact/panel';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";


import Table from "./settingsAdminTable";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";


class SettingsAdmins extends React.Component {

    constructor(props) {
        super(props);

        this.state = {spieler: []};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.changeSpielerAdminRole = this.changeSpielerAdminRole.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.user + "/all",
            json => {
                this.setState({spieler: json});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der Spielerdaten: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der Spielerdaten: " + error.message);
            })
    }

    changeSpielerAdminRole(id) {
        this.showError(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/changeadminrole",
            {id},
            () => {
                this.showInfo("Adminrecht wurde erfolgreich geändert.");
                this.loadData();
            },
            responseNotOk => {
                this.showError("Fehler beim Ändern des Adminrechts:" + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Ändern des Adminrechts:" + error.message);
            })
    }


    render() {
        return <div style={{textAlign:"left"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>- Admins können Trainingssession abschliessen. Sofern eine Session nicht innerhalb von 2 Tagen nach
                    Start abgeschlossen wird, erhalten alle Admins eine Email mit der Bitte die Session abzuschliessen.
                </div>
                <div>- Admins können weitere Admins ernennen.</div>
                <div>- Admins können Traininssessions verwalten, die vom System automatisch angelegt werden.</div>
                <div>*** Es sollte normal nicht mehr als 1-2 Admins geben.</div>
                <div>*** Es gibt noch einen Superadmin, der wird hier im System nicht angezeigt. Dieser hat die Rechte
                    eines Admins und noch ein bisschen mehr.
                </div>
            </Fieldset>
            <Panel header="Admins bestimmen" style={{textAlign: "left"}}>
                <Table spieler={this.state.spieler} changeSpielerAdminRole={this.changeSpielerAdminRole}/>
            </Panel>
        </div>
    }
}

SettingsAdmins.propTypes = {
    id: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default SettingsAdmins;