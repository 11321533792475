import React from 'react'
import PropTypes from "prop-types";
import ConfigUtil from "../../../util/ConfigUtil";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import KalenderComponent from "./KalenderComponent";
import {Checkbox} from "primereact/checkbox";

let stompClient = undefined;

class LigaKalenderArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {events:[], orgEvents:[], flatEvents: [], nurInteressanteEventsAnzeigen: false};

        this.websocketConnect = this.websocketConnect.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.generiereKalenderEventsAusDBEvents = this.generiereKalenderEventsAusDBEvents.bind(this);
        this.getGefilterteEvents = this.getGefilterteEvents.bind(this);
        this.getUserIdsZurPlayerIds = this.getUserIdsZurPlayerIds.bind(this);
        this.getPlayerIdsVonSpielenOhneErgebnisMitBeteiligungDesAktuellenBenutzers = this.getPlayerIdsVonSpielenOhneErgebnisMitBeteiligungDesAktuellenBenutzers.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.props.userId);
    }

    componentWillUnmount() {
        if( stompClient !== undefined) {
            console.log("disconnect");
            stompClient.disconnect();
        }
    }

    websocketConnect(userId) {
        let turnierLigaId;
        if( this.props.liga.folgeTurnierId !== null && this.props.liga.folgeTurnierId !== undefined) {
            turnierLigaId = this.props.liga.folgeTurnierId;
        } else {
            turnierLigaId = this.props.liga.id;
        }
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                const destination = "/topic/public/tournament/events/" + turnierLigaId;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived, {darthelfer_user_id: this.props.userId});
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const body = JSON.parse(payload.body);
        let dbEvents = body.events;
        let events = this.generiereKalenderEventsAusDBEvents(dbEvents);
        this.setState({events: events, orgEvents:dbEvents, flatEvents: body.flatEvents});
    }

    generiereKalenderEventsAusDBEvents(dbEvents) {
        let events = [];
        for (const event of dbEvents) {
            let title = event.userEigentuemerName;
            if( event.gegnerName !== null && event.gegnerName !== undefined ) {
                title += " vs. " + event.gegnerName;
            }
            events.push({
                id: event.id,
                title: title,
                start: event.isoTime,
            });
        }

        return events;
    }

    isAktuellerUserTeilnehemerInDerLiga() {
        if( this.props.liga.tournamentPlayerEntities !== undefined && this.props.liga.tournamentPlayerEntities !== null ) {
            for (const player of this.props.liga.tournamentPlayerEntities) {
                if (player.userId === this.props.userId) {
                    return true;
                }
            }
        }
        return false;
    }

    getPlayerIdsVonSpielenOhneErgebnisMitBeteiligungDesAktuellenBenutzers() {
        let playerIds = [];
        let playerIdAktullerUser = this.getPlayerIdVomAktuellenBenutzer();
        for (const groupGame of this.props.liga.tournamentGroupGameEntities) {
            if( groupGame.spielOhneErgebnis) {
                continue;
            }

            let aktuellerUserIstImGroupGameAlsSpieler1 = groupGame.spieler1 === playerIdAktullerUser;
            let aktuellerUserIstImGroupGameAlsSpieler2 = groupGame.spieler2 === playerIdAktullerUser;
            if( aktuellerUserIstImGroupGameAlsSpieler1 || aktuellerUserIstImGroupGameAlsSpieler2)  {
                // Aktueller User ist im Game
                if( groupGame.anzahlSpieler1 === null || groupGame.anzahlSpieler1 === undefined ) {
                    // kein Ergebnis vorhanden.
                    if( aktuellerUserIstImGroupGameAlsSpieler1) {
                        playerIds.push(groupGame.spieler2);
                    } else if( aktuellerUserIstImGroupGameAlsSpieler2) {
                        playerIds.push(groupGame.spieler1);
                    }
                }
            }
        }
        return playerIds;
    }

    getUserIdsZurPlayerIds(playerIds) {
        let userIds = [];
        for (const playerId of playerIds) {
            for (const player of this.props.liga.tournamentPlayerEntities) {
                if( player.id === playerId) {
                    userIds.push(player.userId);
                }
            }
        }
        return userIds;
    }

    getPlayerIdVomAktuellenBenutzer() {
        for (const player of this.props.liga.tournamentPlayerEntities) {
            if( player.userId === this.props.userId) {
                return player.id;
            }
        }
        return undefined;
    }


    getGefilterteEvents() {
        if( this.state.nurInteressanteEventsAnzeigen) {
            let playerIds = this.getPlayerIdsVonSpielenOhneErgebnisMitBeteiligungDesAktuellenBenutzers();
            let userIds = this.getUserIdsZurPlayerIds(playerIds);
            let events = [];
            for (const event of this.state.orgEvents) {
                if( event.userEigentuemerId === this.props.userId) {
                    events.push(event);
                } else if( userIds.indexOf(event.userEigentuemerId) >= 0 && (event.gegnerId === null || event.gegnerId === undefined)) {
                    events.push(event);
                }
            }
            return this.generiereKalenderEventsAusDBEvents(events);
        }
        return this.state.events;
    }

    render() {
        let readonly = !this.isAktuellerUserTeilnehemerInDerLiga();
        return (
            <div className='demo-app'>
                <div style={{paddingTop:20, paddingBottom:20, textAlign:"left"}}>
                    <Checkbox inputId="nurInteressanteEventsAnzeigen"  onChange={e => this.setState({nurInteressanteEventsAnzeigen: !this.state.nurInteressanteEventsAnzeigen})}
                              checked={this.state.nurInteressanteEventsAnzeigen} style={{marginRight:5}}/>
                    nur freie Termine anzeigen wo mit dem Teilnehmer noch offene Spiele gegen mich vorhanden sind.
                </div>
                <KalenderComponent userId={this.props.userId} tournamentId={this.props.liga.id} readOnly={readonly} events={this.getGefilterteEvents()}
                                   orgEvents={this.state.orgEvents} flatEvents={this.state.flatEvents}/>
            </div>
        )
    }

}


LigaKalenderArea.propTypes = {
    liga: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};



export default LigaKalenderArea
