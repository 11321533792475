import React from 'react'
import {Panel} from 'primereact/panel';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import SelectPlayerComponent from "../../general/SelectPlayerComponent";

import Table from "./headToAllTable";
import {Fieldset} from "primereact/fieldset";
import {HeadToHeadStatistik} from "./headToAllStatistik";
import DHDatePicker from "../../general/DHDatePicker";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class StatisticsHeadToAll extends React.Component {

    constructor() {
        super();
        this.state = {
            spieler: undefined,
            vondatum: undefined,
            bisdatum: undefined,
        };

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loescheSpieler = this.loescheSpieler.bind(this);
        this.merkeSpieler = this.merkeSpieler.bind(this);
    }

    loadData(spielerId, vondatum, bisdatum) {
        if(spielerId === undefined) {
            return;
        }
        let data = {playerid: spielerId, von: vondatum, bis: bisdatum};
        let url = ConfigUtil.getConfig().resourceUrls.statistics + "/headtoall";
        FetchUtil.fetchPost(url,
            data,
            json => {
                console.log(json);
                this.setState({...this.state,headToAllPlayerDtos: json.headToAllPlayerDtos,
                    gewonneneSpieleGesamt: json.gewonneneSpieleGesamt,
                    verloreneSpieleGesamt: json.verloreneSpieleGesamt,
                    gewonneneLegsGesamt: json.gewonneneLegsGesamt,
                    verloreneLegsGesamt: json.verloreneLegsGesamt}
                );
            },
            responseNotOk => {
                this.showError("Fehler beim Laden der head to all Statistik: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden der head to all Statistik: " + error.message);
            });
    }

    loescheSpieler(spieler) {
        this.setState({spieler: undefined, ergebnisse: undefined});
    }

    merkeSpieler(spieler) {
        this.setState({...this.state, spieler: spieler});
        this.loadData(spieler.id, this.state.vondatum, this.state.bisdatum);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    render() {
        return <div style={{textAlign: "left", align: "center", backgroundColor: "white", color: "black", padding: "20px"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>
                    - Es werden immer maximal die letzten 5 Begegnungen angezeigt, sofern du mehr sehen willst findest du alle Ergebnisse zu diesem Gegner unter 'head to head'
                </div>
                <div>
                    - Die Statistik zu Legs u. Spielen wird vollständig (keine Begrenzung auf 5 Spiele) angezeigt.
                </div>
            </Fieldset>
            <Panel header="Head to All Vergleich">
                <div className="grid">
                    <div className="col-fixed" style={{textAlign: "left"}}>
                        <SelectPlayerComponent
                            player={this.state.spieler}
                            selectButtonLabel="Spieler auswählen"
                            callBackSelectFunc={this.merkeSpieler}
                            callBackDeleteFunc={this.loescheSpieler}
                            callBackOnErrorFunc={this.showError}/>
                    </div>
                </div>
                <div className="grid" style={{marginTop: '20px', marginBottom: "20px", textAlign:"left"}}>
                    <div className="col-fixed" style={{width: 150, textAlign: "left"}}><span style={{marginRight:"5px"}}>Optionale Eingabe</span></div>
                    <div className="col-fixed" style={{width: 120, textAlign: "left"}}>
                        Von Datum:
                    </div>
                    <div className="col-fixed" style={{width: 200, textAlign: "left"}}>
                        <DHDatePicker disabled={false} value={this.state.vondatum} changeValue={(date) => this.setState({vondatum: date})}/>
                    </div>
                    <div className="col-fixed" style={{width: 120, textAlign: "left"}}>
                        Bis Datum:
                    </div>
                    <div className="col-fixed" style={{width: 200, textAlign: "left"}}>
                        <DHDatePicker disabled={false} value={this.state.bisdatum} changeValue={(date) => this.setState({bisdatum: date})}/>
                    </div>
                </div>
                {this.state.spieler !== undefined ?
                    <div>
                        <HeadToHeadStatistik gewonneneSpieleGesamt={this.state.gewonneneSpieleGesamt}
                                             verloreneSpieleGesamt={this.state.verloreneSpieleGesamt}
                                             gewonneneLegsGesamt={this.state.gewonneneLegsGesamt}
                                             verloreneLegsGesamt={this.state.verloreneLegsGesamt}/>
                        <div style={{marginTop:"30px"}}>
                            <Table spieler={this.state.spieler}  headToAllPlayerDtos={this.state.headToAllPlayerDtos}/>
                        </div>
                    </div>
                    :
                    <div/>
                }
            </Panel>
        </div>
    }

}

StatisticsHeadToAll.propTypes = {};

export default StatisticsHeadToAll