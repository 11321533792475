import React from 'react'
import {Panel} from 'primereact/panel';
import TrainingsSessionSelectComponent from "../../general/TrainingsSessionSelectComponent";
import Trainingsergebnisse from "../../general/Trainingsergebnisse";
import Trainingtabelle from "../../general/Trainingtabelle";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class StatisticsTrainingsessions extends React.Component {

    constructor() {
        super();
        this.state = {trainigssession: undefined, counter:0};

        this.merkeTrainingssession = this.merkeTrainingssession.bind(this);
    }

    merkeTrainingssession(trainingssession) {
        this.setState({trainingssession: trainingssession, counter: this.state.counter +1 });
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    render() {
       return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black", padding: "20px"}}>
           <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                               onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                               onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                               onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
           <Panel header="Trainingsessions">

               <div className="grid" style={{marginBottom: "40px", textAlign:"left"}}>
                   <div className="col-fixed" style={{width: '150px'}}>Trainingssession:</div>
                   <div className="col">
                       <TrainingsSessionSelectComponent
                           trainingssession={this.state.trainingssession}
                           callBackSelectFunc={this.merkeTrainingssession}
                           callBackDeleteFunc={() => this.setState({...this.state, trainingssession: undefined})}
                           callBackOnErrorFunc={this.showError}
                            loadAll={true} zeigeVorwaertsRueckwaerts={true}
                       selectLast={true}/>
                   </div>
               </div>
               {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                   <Trainingsergebnisse trainingssessionid={this.state.trainingssession.id}
                                        counter={this.state.counter}/> : <div/>
               }
               {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                   <Trainingtabelle trainingssessionid={this.state.trainingssession.id} loadGesamt={false} ueberschrift="Tabelle zur Trainingssession"/> : <div/>
               }
           </Panel>
        </div>
    }

}

StatisticsTrainingsessions.propTypes = {};

export default StatisticsTrainingsessions