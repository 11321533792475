import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHDialog from "../general/DHDialog";
import DateUtil from "../../util/DateUtil";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {TableContainer} from "@mui/material";
import ConfirmDialog from "../general/ConfirmDialog";
import NuPlayerMatchComponent from "./NuPlayerMatchComponent";
import NuEmailadressenComponent from "./NuEmailadressenComponent";
import NuSettingsComponent from "./NuSettingsComponent";
import DHButtonDefault from "../general/DHButtonDefault";
import {Search} from "@mui/icons-material";
import DHButtonProgress from "../general/DHButtonProgress";
import RefreshIcon from '@mui/icons-material/Refresh';
import DHButtonProgressIcon from "../general/DHButtonProgressIcon";

export default function NuSyncComponent({eventOperatorId, onRefresh, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [statusLoaded, setStatusLoaded] = useState(false);
    const [working, setWorking] = useState(false);
    const [workingSync, setWorkingSync] = useState(false);

    const [tournaments, setTournaments] = useState([]);
    const [meetings, setMeetings] = useState([]);

    const [teamIgnoreListe, setTeamIgnoreListe] = useState([]);
    const [showSyncDialog, setShowSyncDialog] = useState(false);
    const [showSpielerVerknuepfenDialog, setShowSpielerVerknuepfenDialog] = useState(false);
    const [showEmailadressenDialog, setShowEmailadressenDialog] = useState(false);
    const [showEinstellungenDialog, setShowEinstellungenDialog] = useState(false);

    useEffect( () => {
        if( meetings === null || meetings === undefined || meetings.length === 0) {
            setTournaments([]);
        }
        ladeMeetingStatus();
    } ,[meetings]);

    const ladeMeetingStatus = () => {
        setErrorMessage(undefined);
        setStatusLoaded(false);

        let data = [];
        for (const meeting of meetings) {
            data.push(meeting.meetingUuid);
        }
        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/status",
            data,
            json => {
                setTournaments(json);
                setStatusLoaded(true);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
                setStatusLoaded(false);
            },
            error => {
                setErrorMessage(error.message);
                setStatusLoaded(false);
            }
        );
    }

    const getMeetings = () => {
        setErrorMessage(undefined);
        setWorking(true);
        setStatusLoaded(false);

        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meetings/" + eventOperatorId,
            {},
            result => {
                console.log("NU sync:", result);
                setMeetings(result);
                setWorking(false);
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
                setWorking(false);
            },
            error => {
                setErrorMessage(error.message);
                setWorking(false);
            }
        );
    }

    const syncMeeting = (meeting) => {
        setErrorMessage(undefined);
        setWorkingSync(true);
        setStatusLoaded(false);

        let data = {externMeetingId: meeting.meetingUuid, playMode: meeting.playMode, mannschaftHeim: meeting.teamHome, mannschaftGast: meeting.teamGuest, beginn: meeting.scheduled }

        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meeting/sync/" + eventOperatorId,
            data,
            () => {
                ladeMeetingStatus();
                setWorkingSync(false);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
                setWorkingSync(false);
            },
            error => {
                setErrorMessage(error.message);
                setWorkingSync(false);
            }
        );
    }

    const syncMeetings = () => {
        setErrorMessage(undefined);
        setWorkingSync(true);
        setStatusLoaded(false);
        setShowSyncDialog(false);

        let data = [];

        for (const meeting of getGefilterteMeetings()) {
            if( meeting.isCompleted ) {
                continue;
            }
            data.push({externMeetingId: meeting.meetingUuid, playMode: meeting.playMode, mannschaftHeim: meeting.teamHome, mannschaftGast: meeting.teamGuest, beginn: meeting.scheduled });
        }

        return FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/meetings/sync/" + eventOperatorId,
            data,
            () => {
                ladeMeetingStatus();
                setWorkingSync(false);
                onRefresh();
            },
            responseNotOk => {
                setErrorMessage(responseNotOk.message);
                setWorkingSync(false);
            },
            error => {
                setErrorMessage(error.message);
                setWorkingSync(false);
            }
        );
    }

    const getTournamentArea = (externMeetingId) => {
        if( !statusLoaded ) {
            return <img src="/images/dot_yellow.png" style={{width:20}}/>
        }

        let tmp = tournaments.filter(dto => dto.externMeetingId === externMeetingId);
        if( tmp.length === 0 ) {
            return <img src="/images/dot_red.png" style={{width:20}}/>
        }
        let tournament = tmp[0];
        return <span>
            <img src="/images/dot_green.png" style={{width: 20, marginRight:10}}/>
            <span style={{opacity: 0.5}}>{tournament.mannschaftHeim} vs. {tournament.mannschaftGast} / {tournament.beginnMitUhrzeitString}</span>
        </span>
    }

    const getGefilterteMeetings = () => {
        let liste = [];
        for (const meeting of meetings) {
            if (teamIgnoreListe.includes(meeting.teamHome)) {
                continue;
            }
            liste.push(meeting);
        }
        return liste;
    }

    const getTable = () => {
        let styleWithBackground = {color: "#022836", background:"#eaf1f5", fontSize: 16, padding:5};
        let styleWithoutBackground = {color: "#022836", background:"white", fontSize: 16, padding:5};
        let rows = [];
        let background = false;
        for (const meeting of getGefilterteMeetings()) {
            if( teamIgnoreListe.includes(meeting.teamHome)) {
                continue;
            }

            let style = background ? styleWithBackground : styleWithoutBackground;
            rows.push(<TableRow key={"key_meeting_" + meeting.meetingUuid}>
                <TableCell style={style}>
                    {DateUtil.localDateTimeToDateTimeString(meeting.scheduled)}
                    {meeting.isCompleted && <>
                        <br/>
                        <span style={{opacity: 0.5, fontSize:12}}>abgeschlossen</span>
                    </>}

                </TableCell>
                <TableCell style={style}>
                    {!meeting.isCompleted &&
                        <DHButtonProgressIcon icon={<RefreshIcon/>} disabled={false} working={workingSync} onClick={() => syncMeeting(meeting)}/>
                    }
                </TableCell>
                <TableCell style={style}>
                    {meeting.teamHome} vs. {meeting.teamGuest}<br/>
                    {getTournamentArea(meeting.meetingUuid)}
                </TableCell>
            </TableRow>);
            background = !background;
        }
        return <TableContainer style={{ maxHeight: "100%", paddingBottom:0 }}>
            <Table style={{backgroundColor:"#022836", color:"white"}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, width:170, padding:5}}>Beginn</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, width:50, padding:5}}>#</TableCell>
                        <TableCell style={{backgroundColor: "#ac1c27", color:"white", fontSize:16, padding:5}}>Bezeichnung</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    }

    const changeTeamIgnoreListe = (teamName) => {
        if( teamIgnoreListe.includes(teamName) ) {
            let newListe = [...teamIgnoreListe];
            newListe = newListe.filter(tmp => tmp !== teamName);
            setTeamIgnoreListe(newListe);
        } else {
            let newListe = [...teamIgnoreListe];
            newListe.push(teamName);
            setTeamIgnoreListe(newListe);
        }
    }

    const getTeamFilterArea = () => {
        if( meetings.length === 0) {
            return "";
        }
        let bereitsHinzugefuegt = [];
        let buttons = [];
        for (const meeting of meetings) {
            if( !bereitsHinzugefuegt.includes(meeting.teamHome)) {
                buttons.push(<DHButtonDefault label={meeting.teamHome}
                                     onClick={() => changeTeamIgnoreListe(meeting.teamHome)}
                                              active={!teamIgnoreListe.includes(meeting.teamHome)}
                                     style={{marginTop:5}}/> );
                bereitsHinzugefuegt.push(meeting.teamHome);
            }
        }
        return <div>
            <div style={{display: "flex", margin: "auto", verticalAlign: "middle", alignItems: "center"}}>
                <span style={{marginRight: 5, marginTop:5}}>Teamfilter:</span>
                {buttons}
            </div>
        </div>
    }

    return <DHDialog showCloseButton={true} title="BDV/nu Aktionen" onClose={onClose} comp={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{marginBottom: 20}}>
            <DHButtonProgress label="Spieltage von BDV/nu abrufen" working={working} disabled={false} onClick={() => getMeetings()}
                              icon={<Search/>}  style={{marginRight:2}}/>
            <DHButtonDefault label="nu -> Darthelfer Spieler Verknüpfungen" onClick={() => setShowSpielerVerknuepfenDialog(true)} disabled={false}
                             icon={undefined} style={{marginRight:2}}/>
            <DHButtonDefault label="Einstellungen" onClick={() => setShowEinstellungenDialog(true)} disabled={false} icon={undefined} style={{marginRight:2}}/>
            <DHButtonDefault label="Emailadressen" onClick={() => setShowEmailadressenDialog(true)} disabled={false} icon={undefined} style={{marginRight:2}}/>
        </div>
        {getTeamFilterArea()}
        {getGefilterteMeetings().length > 0 &&
            <div style={{marginTop:10, marginBottom:10}}>
                <DHButtonProgress label="Alle gefilterten Spieltage synchronisieren" working={workingSync} disabled={false} onClick={() => setShowSyncDialog(true)}
                icon={<RefreshIcon/>}/>
            </div>
        }
        {getTable()}

        {showSyncDialog &&  <ConfirmDialog visible={true}
                                                      callBackOnNein={() => setShowSyncDialog(false)}
                                                      callBackOnJa={() => syncMeetings()}
                                                      header="Synchronisieren"
                                                      textOben={"Alle selektierten (angezeigten), nicht abgeschlossene,  Spieltage werden nach Darthelfer synchronsiert."}
                                                      textUnten="Möchtest du die Synchronisation wirklich durchführen."/>
        }
        {showSpielerVerknuepfenDialog &&
            <NuPlayerMatchComponent eventOperatorId={eventOperatorId} onClose={() => setShowSpielerVerknuepfenDialog(false)} />
        }
        {showEmailadressenDialog &&
            <NuEmailadressenComponent eventOperatorId={eventOperatorId} onClose={() => setShowEmailadressenDialog(false)} />
        }
        {showEinstellungenDialog &&
            <NuSettingsComponent eventOperatorId={eventOperatorId} onClose={() => setShowEinstellungenDialog(false)} />
        }
    </>} />
}

NuSyncComponent.propTypes = {
    eventOperatorId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
}
