import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHDefaultDrawer from "../general/DHDefaultDrawer";
import ConfirmDialog from "../general/ConfirmDialog";
import DHTextField from "../general/DHTextField";
import DHButtonSave from "../general/DHButtonSave";
import DeleteIcon from "@mui/icons-material/Delete";
import DHButtonIconDelete from "../general/DHButtonIconDelete";

export default function NuEmailadressenComponent({eventOperatorId, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [email, setEmail] = useState("");
    const [emailAdressen, setEmailAdressen] = useState([]);

    const [deleteId, setDeleteId] = useState(undefined);


    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/email/laden/"+ eventOperatorId,
            json => {
                setEmailAdressen(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const deleteEmailadresse = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.nu + "/email/delete/" + eventOperatorId + "/" + deleteId,
            {},
            () => {
                setInfoMessage("Email wurde gelöscht.");
                ladeDaten();
                setDeleteId(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setDeleteId(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setDeleteId(undefined);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/email/save/" + eventOperatorId,
            {email: email},
            json => {
                setInfoMessage("Emailadresse wurde gespeichert.");
                setEmail("");
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler:" + error.message)
            })
    }


    const getHinweisArea = () => {
        return <div style={{marginTop: 20, fontSize: 11}}>
            <strong>Hinweis:</strong><br/>
            Hier kannst du Mailadresse verwalten, die beim Übertragen des Ergebnisses an nu automatisch eine Ligaspieltagzusammenfassung per Mail erhalten.<br/><br/>
            * z.B. Allgemeine Emailadresse deines Verbandes bei komplett digitalem Spielbetrieb.<br/><br/>
            Du kannst direkt am Ligaspieltag noch eine weitere Adresse eingeben, z.B. für den  Kapitän der Gastmannschaft<br/><br/>
            Sofern du eine fehlerhafte Emailadresse gespeichert hast, lösche diese einfach weg und gebe eventuell eine neue Emailadresse ein.
        </div>
    }

    const getNewEmailadressArea = () => {
        return <div>
            <div style={{marginBottom: 10}}>
                <DHTextField id="id-email" required={true} width="100%" label="Emailadresse" value={email} onChange={(text) => setEmail(text)}/>
            </div>
            <div style={{marginBottom: 5}}>
                <DHButtonSave working={false} disabled={email === ""} onClick={() => save()}/>
            </div>
        </div>
    }

    const getRows = () => {
        let rows = [];
        for (const email of emailAdressen) {
            rows.push( <div key={"key_email_" + email.id} style={{display: "flex", alignItems: "center"}}>
                    <DHButtonIconDelete icon={<DeleteIcon />} onClick={() => setDeleteId(email.id)} />
                    {email.email}
                </div>)
        }
        return rows;
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title={"Emailadressen verwalten"} children={
        <div style={{minWidth: 350, padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
            {getNewEmailadressArea()}
            <hr/>
            <div>
                <div style={{marginBottom:10}}>Bestehende Emailadressen:</div>
                <div>
                    {emailAdressen.length === 0 && <div style={{marginTop:20}}>Aktuell keine Emailadressen vorhanden.</div>}
                    {emailAdressen.length > 0 && getRows()}
                </div>
            </div>
            <hr/>
            {getHinweisArea()}
            {deleteId !== undefined &&
                <ConfirmDialog visible={true}
                               callBackOnNein={() => setDeleteId(undefined)}
                               callBackOnJa={() => deleteEmailadresse()}
                               header="Emailadresse löschen"
                               textOben=""
                               textUnten="Möchtest du die Emailadresse wirklich löschen?"/>
            }
        </div>
    }/>
}