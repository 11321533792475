import React from 'react'
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DateUtil from "../../../util/DateUtil";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class NotificationsDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.showError = this.showError.bind(this);
        this.alleGelesenMarkieren = this.alleGelesenMarkieren.bind(this);
        this.gelesenMarkieren = this.gelesenMarkieren.bind(this);
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    getListe() {
        if( this.props.notifications === null || this.props.notifications === undefined) {
            return <div>Keine Benachrichtigungen vorhanden.</div>
        }
        let rows = [];
        for (const notification of this.props.notifications) {
            rows.push(<div style={{cursor:"pointer"}}
                           onClick={() => this.gelesenMarkieren(notification.id, notification.gelesen)}
            key={"key_notification_" + notification.id}>
                <span style={!notification.gelesen ? {fontWeight:"bold"} : {}}>{DateUtil.localDateTimeToString(notification.time)}<br/>{notification.text}</span>
                     <hr/>
            </div>)
        }
        return <div>
            {rows}
        </div>
    }

    alleGelesenMarkieren() {
        let url = ConfigUtil.getConfig().resourceUrls.notification + "/allegelesen";
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.props.callBackCloseDialog();
            },
            responseNotOk => {
                this.showError("Fehler beim Lesen der Mitteilung: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Lesen der Mitteilung " + error.message);
            });
    }

    gelesenMarkieren(notificationId, gelesen) {
        if( gelesen) {
            return;
        }
        let url = ConfigUtil.getConfig().resourceUrls.notification + "/gelesen?notificationId=" + notificationId;
        FetchUtil.fetchPost(url,
            {},
            json => {
            },
            responseNotOk => {
                this.showError("Fehler beim Lesen der Mitteilung: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Lesen der Mitteilung " + error.message);
            });
    }

    render() {
        return <DHDialog onClose={() => this.props.callBackCloseDialog()} title="Benachrichtigungen" show={true} showCloseButton={true}
                         comp={<>
                             <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                                 onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                                 onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                                 onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
                             <div style={{marginTop:10, marginBottom:30, cursor:"pointer"}} onClick={() => this.alleGelesenMarkieren()}>Alle Benachrichtigungen als gelesen markieren...</div>
                             {this.getListe()}
                         </>}/>
    }
}

NotificationsDialog.propTypes = {
    visible : PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    callBackCloseDialog: PropTypes.func.isRequired,
};

export default NotificationsDialog