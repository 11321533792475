import React from "react";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import CsvDownloader from "react-csv-downloader";

export default function ExportPlayersButton({players}) {

    const getColumns = () => {
        let cols = [
            {
                id: 'cell1',
                displayName: 'SpielerId',
            },
            {
                id: 'cell2',
                displayName: 'Name',
            },
            {
                id: 'cell3',
                displayName: 'Vorname',
            },
            {
                id: 'cell4',
                displayName: 'Nachname',
            },
            {
                id: 'cell5',
                displayName: 'Telefon',
            },
            {
                id: 'cell6',
                displayName: 'Email',
            },
            {
                id: 'cell7',
                displayName: 'Vereinsname',
            },
        ];
        return cols;
    }

    const replaceChars = (value) => {
        if( value === null || value === undefined) {
            return "";
        }
        return ""+ value.replace(";", ",");
    }

    const getData = () => {
        let rows = [];
        for (const player of players) {
            let name = replaceChars(player.name);
            let vorname = replaceChars(player.vorname);
            let nachname = replaceChars(player.nachname);
            let telefon = replaceChars(player.telefon);
            let email = replaceChars(player.email);
            let vereinsname = replaceChars(player.vereinsname);
            rows.push({cell1: player.idShort, cell2: name, cell3: vorname, cell4: nachname, cell5: "Tel.: " + telefon, cell6: email, cell7: vereinsname});
        }
        return rows;
    }

    return <CsvDownloader filename="teilnehmer.csv" datas={getData()} columns={getColumns()} separator=";">
        <Button hr icon={PrimeIcons.DOWNLOAD} label="Teilnehmer exportieren (csv)"/>
    </CsvDownloader>
}
