import React from 'react'
import TrainingsSessionSelectComponent from "../../general/TrainingsSessionSelectComponent";
import {Panel} from 'primereact/panel';

import Trainingsergebnisse from "../../general/Trainingsergebnisse";

import TrainingGameEditComponent from "./TrainingGameEditComponent";
import Trainingtabelle from "../../general/Trainingtabelle";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

const INITIAL_STATE = {
    details: false,
    trainingssession: {},
    counter: 0
};


class TrainingGameEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.callBackOnDataChanged = this.callBackOnDataChanged.bind(this);
        this.merkeTrainingssession = this.merkeTrainingssession.bind(this);
    }

    callBackOnDataChanged() {
        this.setState({counter: this.state.counter + 1});
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    merkeTrainingssession(trainingssession) {
        this.setState({trainingssession: trainingssession});
    }

    render() {
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <div className="pageHeader" style={{backgroundColor:"#c1f1ec"}}>
                Trainingsspiel
            </div>
            <div className="grid" style={{padding:20, marginLeft:0, marginRight:0}}>
                <div className="col-fixed" style={{width: '100px', textAlign: "right"}}>Training:</div>
                <div className="col" style={{textAlign: "left"}}>
                    <TrainingsSessionSelectComponent
                        trainingssession={this.state.trainingssession}
                        callBackSelectFunc={this.merkeTrainingssession}
                        callBackDeleteFunc={() => this.setState({...this.state, trainingssession: undefined})}
                        callBackOnErrorFunc={this.showError}
                        zeigeVorwaertsRueckwaerts={false}/>
                </div>
            </div>
            {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                <div style={{paddingLeft:20, paddingRight:20}}>
                    <TrainingGameEditComponent trainingssession={this.state.trainingssession}
                                               callBackOnInfo={this.showInfo}
                                               callBackOnError={this.showError}
                                               clearMessages={() => this.showError(undefined)}
                                               callBackOnDataChanged={this.callBackOnDataChanged}/>
                </div>
                : <div/>
            }
            {this.state.trainingssession !== undefined && this.state.trainingssession.id !== undefined ?
                <div style={{padding:20}}>
                    <Panel header="Trainingsergebnisse" style={{marginTop:"10px"}}>
                        <Trainingsergebnisse trainingssessionid={this.state.trainingssession.id}
                                             counter={this.state.counter}/>
                    </Panel>
                    <Panel header="Tabelle" style={{marginTop:"10px"}}>
                        <Trainingtabelle trainingssessionid={this.state.trainingssession.id}
                                         loadGesamt={false}
                                         ueberschrift="Tabelle zur Trainingssession"
                                         counter={this.state.counter}/>
                    </Panel>
                </div>
                : <div/>
            }
        </div>
    }
}

TrainingGameEdit.propTypes = {};

export default TrainingGameEdit;