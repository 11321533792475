import React from 'react'
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

const INITIAL_STATE = {
    password: "",
    password2: ""
};

class ForumUserAnlegenArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }


    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    save() {
        this.showError(undefined);
        if( this.state.password.trim() === "") {
            this.showError("Bitte aktuellen Passort eingeben, damit wir ihre Identität überprüfen können.");
            return;
        }
        if( this.state.helpdesk !== this.state.helpdesk2) {
            this.showError("Die beiden Passwörter für das Forum stimmen nicht überein.");
            return;
        }
        let data = {
            currentPassword: this.state.password,
            helpdeskPassword: this.state.helpdesk
        };
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.user + "/registerHelpdesk",
            data,
            json => {
                this.showInfo("Forumszugang wird angelegt, bitte haben Sie noch ein wenig Geduld");
                this.setState({helpdeskRequested: true });
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        if ((this.props.helpdeskId !== null && this.props.helpdeskId !== "")) {
            return <div className="ligashortoverview"
                        style={{height: "100%", borderLeft: "10px solid yellow", textAlign: "left"}}>
                <h3>Forumbenutzer</h3>
                <div className="grid" style={{marginTop: 20, marginBottom:20, fontSize:13}}>
                    <div className="col">Du hast bereits einen Benutzer im Supportforum von darthelfer.de unter <a href="https://support.darthelfer.de">https://support.darthelfer.de</a>.</div>
                </div>
                <div className="grid" style={{marginTop: 20, fontSize:13}}>
                    <div className="col">Dein Benutzername lautet:</div>
                    <div className="col"><em>{this.props.username}</em></div>
                </div>
                <div className="grid" style={{marginBottom:20, fontSize:13}}>
                    <div className="col">Dein Benutzer hat die Id:</div>
                    <div className="col"><em>{this.props.helpdeskId}</em>.</div>
                </div>
                <div className="grid" style={{marginTop: 20, marginBottom:20, fontSize:13}}>
                    <div className="col">Vielen Dank, dass du an unserer Community teilnimmst.</div>
                </div>
            </div>
        } else if (this.props.helpdeskRequested || this.state.helpdeskRequested) {
            return <div className="ligashortoverview"
                 style={{height: "100%", borderLeft: "10px solid yellow", textAlign: "left"}}>
                <h3>Forumbenutzer wird angelegt</h3>
                <div className="grid" style={{marginTop: 20, marginBottom:20, fontSize:13}}>
                    <div className="col">Deine Anfrage wird bearbeitet, bitte habe ein wenig Geduld. In der Regel wird die Anfrage innerhalb von 15 Minuten automatisch bearbeitet.</div>
                </div>
            </div>
        } else  if (!this.props.helpdeskRequested && !this.state.helpdeskRequested) {
            return <div className="ligashortoverview"
                        style={{height: "100%", borderLeft: "10px solid yellow", textAlign: "left"}}>
                <h3>Forumbenutzer anlegen 1{this.state.helpdeskRequested}2{this.props.helpdeskRequested}3</h3>
                <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                    onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                    onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                    onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
                <div className="grid" style={{marginTop: 20, marginBottom:20, fontSize:13}}>
                    <div className="col">Um im Supportforum von darthelfer.de unter <a href="https://support.darthelfer.de">https://support.darthelfer.de</a> Beiträge usw. verfassen zu können, musst du einen Zugang hierüber anlegen.</div>
                </div>
                <div className="grid" style={{marginTop: 20, marginBottom:20}}>
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Usernamen:</div>
                    <div className="col">{this.props.username}</div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort (darthelfer.de):</div>
                    <div className="col"><Password name="password" value={this.state.password} toggleMask={true}
                                                     onChange={(e) => this.handleChange(e)}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort Forum:</div>
                    <div className="col"><Password name="helpdesk" value={this.state.helpdesk} toggleMask={true}
                                                     onChange={(e) => this.handleChange(e)} feedback={false}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort Forum wiederholen:</div>
                    <div className="col"><Password name="helpdesk2" value={this.state.helpdesk2} toggleMask={true}
                                                     onChange={(e) => this.handleChange(e)} feedback={false}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                    <div className="col"><Button label="Forumbenutzer anlegen" onClick={() => this.save()}/></div>
                </div>
            </div>
        }
    }
}

ForumUserAnlegenArea.propTypes = {
    userId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    helpdeskId: PropTypes.string,
    helpdeskRequested: PropTypes.bool,
};

export default ForumUserAnlegenArea;
