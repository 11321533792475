import React, {useEffect, useState} from 'react'
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {Dropdown} from "primereact/dropdown";
import EventOperatorRanglisteTable from "./EventOperatorRanglisteTable";
import {Button} from "primereact/button";
import PublicTournamentStatistikTab from "../tournaments/PublicTournamentStatistikTab";
import {ProgressSpinner} from "primereact/progressspinner";
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";


export default function PublicEventOperatorRanglisteTab({eventOperatorId, ranking}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [tournaments, setTournaments] = useState([]);
    const [playersWelcheNichtBeruecksichtigtWurden, setPlayersWelcheNichtBeruecksichtigtWurden] = useState([]);
    const [teilnahmenGesamt, setTeilnahmenGesamt] = useState(0);
    const [ranglisteInfos, setRanglisteInfos] = useState(undefined);
    const [rangliste, setRangliste] = useState(undefined);
    const [ranglisten, setRanglisten] = useState([]);
    const [statistik, setStatistik] = useState(undefined);

    const [showRangliste, setShowRangliste] = useState(true);
    const [showStatistik, setShowStatistik] = useState(false);
    const [showProgress, setShowProgress] = useState(false);

    const [selectedRangliste, setSelectedRangliste] = useState(undefined);

    useEffect(() => {
        ladeRanglisten();
        ladeStatistik();
    }, [eventOperatorId]);

    useEffect(() => {
        if( selectedRangliste === null || selectedRangliste === undefined) {
            return;
        }
        setShowRangliste(true);
        setShowStatistik(false);
        setStatistik(undefined);
        ladeRangliste(selectedRangliste.id);
    }, [selectedRangliste]);

    useEffect(() => {
        if( selectedRangliste === null || selectedRangliste === undefined) {
            return;
        }
        if( !showStatistik || statistik !== undefined) {
            return;
        }
        // wird nur geladen, wenn nicht schon geladen.
        ladeStatistik(selectedRangliste.id);
    }, [showStatistik]);

    function showError(message) {
        setErrorMessage(message);
    }

    const ladeRanglisten = () => {
        if( eventOperatorId === undefined || eventOperatorId === null) {
            return;
        }
        setShowProgress(true);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/ranglisten/eventoperator/" + eventOperatorId,
            json => {
                let ranglisten = [...json];
                setRanglisten(ranglisten);
                setShowProgress(false);
                if( ranglisten !== null && ranglisten !== undefined && ranglisten.length > 0 ) {
                    if( ranking !== undefined && ranking !== "") {
                        // vorbelegung über url;
                        let tmp = ranglisten.filter(rangliste => rangliste.id === ranking);
                        if( tmp.length === 1) {
                            setSelectedRangliste(tmp[0]);
                        } else {
                            setSelectedRangliste(ranglisten[0]);
                        }
                    } else {
                        setSelectedRangliste(ranglisten[0]);
                    }
                }
            },
            responseNotOk => {
                showError("Fehler: " + responseNotOk.message);
                setShowProgress(false);
            },
            error => {
                showError("Fehler: " + error.message);
                setShowProgress(false);
            }
        );
    }

    const ladeRangliste = (ranglisteId) => {
        if( ranglisteId === undefined || ranglisteId === null) {
            return;
        }
        setShowProgress(true);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/rangliste/eventoperator/" + ranglisteId,
            json => {
                setRangliste(json.ranglisteByOperatorDtos);
                setRanglisteInfos(json.rangliste);
                setTournaments(json.tournamentDtos);
                setPlayersWelcheNichtBeruecksichtigtWurden(json.playersWelcheNichtBeruecksichtigtWurden);
                setTeilnahmenGesamt(json.teilnahmenGesamt);
                setShowProgress(false);
            },
            responseNotOk => {
                showError("Fehler: " + responseNotOk.message);
                setShowProgress(false);
            },
            error => {
                showError("Fehler: " + error.message);
                setShowProgress(false);
            }
        );
    }

    const ladeStatistik = (ranglisteId) => {
        if( ranglisteId === undefined || ranglisteId === null) {
            return;
        }
        setShowProgress(true);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.public + "/rangliste/eventoperator/" + ranglisteId + "/statistik",
            json => {
                setStatistik(json);
                setShowProgress(false);
            },
            responseNotOk => {
                showError("Fehler: " + responseNotOk.message);
                setShowProgress(false);
            },
            error => {
                showError("Fehler: " + error.message);
                setShowProgress(false);
            }
        );
    }

    if( ranglisten === null || ranglisten === undefined || ranglisten.length === 0)  {
        return <div style={{color:"white", fontSize:16, padding:20}}>Keine Ranglisten vorhanden...</div>
    }

    return <div>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding: 20}}>
            <div>
                <Dropdown value={selectedRangliste} options={ranglisten}
                          optionLabel="bezeichnung"
                          onChange={(e) => {
                              setSelectedRangliste(e.value)
                          }}
                          placeholder="Eingabe..." style={{width: 300}}/>
            </div>
            <div style={{marginTop: 10, color: "white", fontSize: 16}}>Teilnahmen gesamt: {teilnahmenGesamt}</div>
        </div>
        <div style={{paddingLeft: 20, paddingBottom:20}}>
            <Button label="Rangliste" style={{width:150}} className={showRangliste? "p-button-primary" : "p-button-secondary"} onClick={() => {
                setShowRangliste(!showRangliste);
                setShowStatistik(!showStatistik);
            }}/>
            <Button label="Statistik" style={{width:150}} className={showStatistik ? "p-buttonOz-primary" : "p-button-secondary"} onClick={() => {
                setShowStatistik(!showStatistik);
                setShowRangliste(!showRangliste);
            }}/>
        </div>
        {showStatistik && statistik !== undefined &&
            <PublicTournamentStatistikTab tournamentId={undefined} participants={statistik.playerDtos}
                                      gesamtStatistik={statistik.gesamtStatistikDto} playerStatistiks={statistik.playerStatistikDtos}/>

        }
        {showRangliste &&
            <EventOperatorRanglisteTable rangliste={rangliste} ranglisteInfos={ranglisteInfos} tournaments={tournaments} playersWelcheNichtBeruecksichtigtWurden={playersWelcheNichtBeruecksichtigtWurden}/>
        }
        {showProgress && <ProgressSpinner style={{width: 150, height: 150}} />}
    </div>
}

