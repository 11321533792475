import React from 'react'
import PropTypes from "prop-types";

import Turniergame from "./Turniergame";
import {Button} from "primereact/button";
import TurniergruppeProblemDialog from "./TurniergruppeProblemDialog";
import {TOURNAMENTSYSTEMS} from "../../../constants/tournamentSystems";

class Turniergruppe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {showProblemDialog: false};

        this.getTableArea = this.getTableArea.bind(this);
        this.getSpielerName = this.getSpielerName.bind(this);
        this.callBackRefresh = this.callBackRefresh.bind(this);
    }

    callBackRefresh() {
        this.props.callBackRefresh();
    }

    getSpielerName(id) {
        if (id === undefined) {
            return "";
        }

        for (let i = 0; i < this.props.tournament.tournamentPlayerEntities.length; i++) {
            let spieler = this.props.tournament.tournamentPlayerEntities[i];
            if (spieler.id === id) {
                let spielername = spieler.name;
                if( spieler.inaktiv) {
                    return <s>{spielername}</s>;
                } else {
                    return spielername;
                }
            }
        }
    }

    isSpielerInAktiv(id) {
        if( id === null || id === undefined) {
            return false;
        }
        for (let i = 0; i < this.props.tournament.tournamentPlayerEntities.length; i++) {
            let spieler = this.props.tournament.tournamentPlayerEntities[i];
            if (spieler.id === id) {
                return spieler.inaktiv;
            }
        }
        return false;
    }

    getGroupEntites() {
        let gruppe = [];
        for (let i = 0; i < this.props.tournament.tournamentGroupGameEntities.length; i++) {
            let gruppenGameentity = this.props.tournament.tournamentGroupGameEntities[i];
            if( gruppenGameentity.gruppe === this.props.gruppennummer) {
                gruppe.push(gruppenGameentity);
            }
        }
        return gruppe;
    }

    isSchweizerSystem() {
        return this.props.tournament.turniersystem === TOURNAMENTSYSTEMS.SCHWEIZERSYSTEM.id;
    }

    getTableArea() {
        if( this.props.gruppenTabelle === null || this.props.gruppenTabelle === undefined ||
            this.props.gruppenTabelle.rangliste === undefined || this.props.gruppenTabelle.rangliste === null || this.props.gruppenTabelle.rangliste.length === 0 ) {
            return "";
        }
        let rows = [];
        let position = 1;
        let buttonbeschriftung = "";
        let ranglisteManuellVorhanden = this.props.gruppenTabelle.ranglisteManuell !== undefined && this.props.gruppenTabelle.ranglisteManuell !== null && this.props.gruppenTabelle.ranglisteManuell.length !== 0;
        if( this.props.gruppenTabelle.existierenProbleme && ranglisteManuellVorhanden ) {
            buttonbeschriftung = "Problem.. manuelles Ergebnis vorhanden";
        } else if (this.props.gruppenTabelle.existierenProbleme && !ranglisteManuellVorhanden){
            buttonbeschriftung = "Problem vorhanden";
        }
        rows.push(<div key={"table_header_1_group_" + this.props.gruppennummer} className="grid" style={{paddingLeft:10,paddingRight:10, paddingTop:10}}>
            <div className="col">
                {this.props.gruppenTabelle.existierenProbleme && !this.props.readOnly ? <Button icon="pi pi-exclamation-triangle" label={buttonbeschriftung} onClick={() => this.setState({showProblemDialog:true})}/> : ""}
            </div>
            <div className="col-fixed" style={{width:50, textAlign:"center", backgroundColor:"#E6946C", fontSize:12}}>Punkte</div>
            <div className="col-fixed" style={{width:75, textAlign:"center", backgroundColor:"#E0AF97", fontSize:12}}>Spiele</div>
            <div className="col-fixed" style={{width:50, textAlign:"center", backgroundColor:"#E6946C", fontSize:12}}>{this.props.isSetModus ? "Sets" : "Legs"}</div>
        </div>);
        rows.push(<div key={"table_header_2_group_" + this.props.gruppennummer} className="grid" style={{paddingLeft:10,paddingRight:10}}>
            <div className="col-fixed" style={{width:20}}></div>
            <div className="col"></div>
            <div className="col-fixed" style={{width:50, backgroundColor:"#E6946C"}}></div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>S</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>U</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>V</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>G</div>
            <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>V</div>
        </div>);
        let anzahlDieWeiterKommen = this.props.tournament.anzahlSpielerProGruppeFuerKOPhase;
        let rangliste = this.props.gruppenTabelle.rangliste;
        if( ranglisteManuellVorhanden ) {
            rangliste = this.props.gruppenTabelle.ranglisteManuell;
        }
        for(let i = 0; i < rangliste.length ;i++) {
            let ranglisteEinzel = rangliste[i];
            let bestplatzierteDto = this.props.bestplatzierteDtos.find(dto => dto.playerId === ranglisteEinzel.playerid)
            let styleRow = (i < anzahlDieWeiterKommen) || bestplatzierteDto ? {backgroundColor: "#82e3d9"} : {};
            if( this.props.selectedPlayerId !== undefined && this.props.selectedPlayerId === ranglisteEinzel.playerid ) {
                styleRow = {...styleRow, backgroundColor: "#FD8943"};
            }
            rows.push(<div  key={"table_header_group_" + this.props.gruppennummer + "_playerid_" + ranglisteEinzel.playerid} className="grid" style={{paddingLeft:10,paddingRight:10, paddingTop:5}}>
                <div className="col-fixed" style={{...styleRow,width:20}}>{position}.</div>
                <div className="col" style={{...styleRow, verticalAlign: "middle", display:"flex"}}>
                    {bestplatzierteDto && bestplatzierteDto.markierung && <img src="/images/questionmark_yellow.png" alt="Auswahl treffen" style={{height:20, marginRight:2}} title={"Zwischen allen Spielern mit diesem Zeichen muss eine Auswahl getroffen werden."}/>}
                    {this.getSpielerName(ranglisteEinzel.playerid)}
                </div>
                <div className="col-fixed" style={{width:50, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.punkte}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.gewonnenespiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.unentschiedenspiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E0AF97", textAlign:"center"}}>{ranglisteEinzel.verlorenespiele}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.anzahlLegsGewonnen}</div>
                <div className="col-fixed" style={{width:25, backgroundColor:"#E6946C", textAlign:"center"}}>{ranglisteEinzel.anzahlLegsVerloren}</div>
            </div>);
            position++;
        }
        return rows;
    }

    render() {
        if( !this.props.showGroup) {
            return "";
        }
        let gruppenEintraege = this.getGroupEntites();

        let rows = [];
        for (const gruppenEintraegeElement of gruppenEintraege) {
            if( this.props.blendeGespielteSpieleAus &&
                (
                    (gruppenEintraegeElement.anzahlSpieler1 !== null && gruppenEintraegeElement.anzahlSpieler2 !== null)
                    || gruppenEintraegeElement.spieler2 === null
                    || this.isSpielerInAktiv(gruppenEintraegeElement.spieler1)
                    || this.isSpielerInAktiv(gruppenEintraegeElement.spieler2)
                )
            ) {
                continue;
            }
            rows.push(<Turniergame tournament={this.props.tournament} tournamentGroup={gruppenEintraegeElement.gruppe} userId={this.props.userId}
                                   gruppennummer={this.props.gruppennummer}
                                   game={gruppenEintraegeElement}
                                   readOnly={this.props.readOnly}
                                   callBackRefresh={this.callBackRefresh}
                                   callbackGetSpielerName={this.getSpielerName}
                                   selectedPlayerId={this.props.selectedPlayerId}
                                   boardGames={this.props.boardGames}
                                   liveGames={this.props.liveGames}
                                   onPlayerSelect={(playerId) => this.props.onPlayerSelect(playerId)}/>);
        }
        let display = this.props.showGroup ? "block" : "none";
        let ranglisteVorhanden = this.props.gruppenTabelle.rangliste !== undefined && this.props.gruppenTabelle.rangliste !== null && this.props.gruppenTabelle.rangliste.length !== 0;
        let zeigeRangliste = this.props.zeigeTabelle && ranglisteVorhanden;
        let label = this.isSchweizerSystem() ? "Runde " + this.props.gruppennummer : "Gruppe " + this.props.gruppennummer;
        return <div style={{backgroundColor:"white", color:"black", textAlign:"left",marginTop:10, display:display, boxShadow:"0 2px 15px #888888"}}>
            <div className="grid" style={{backgroundColor:"#26a69a", color:"#f2f2f2", fontSize:16, fontWeight:"bold", textAlign:"center", paddingLeft:20, marginLeft:0, marginRight:0,marginTop:0}}>
                <div className="col">{label}</div>
                <div className="col-fixed" style={{width:40}}>
                    <img alt="Minimieren" style={{width:20, cursor:"pointer"}} src={"/images/minimize.png"} onClick={()  => this.props.minimizeGroup(this.props.gruppennummer)}/>
                </div>
            </div>
            {
                zeigeRangliste && <div style={{backgroundColor:"#d6f5f3", marginTop:5, paddingBottom:10, borderBottom:"1px solid #26a69a"}}>{this.getTableArea()}</div>
            }
            {rows}
            {this.state.showProblemDialog &&
                <TurniergruppeProblemDialog group={this.props.gruppennummer}
                                            tournament={this.props.tournament}
                                            ranglisteDtos={this.props.gruppenTabelle.rangliste}
                                            ranglisteManuellDtos={this.props.gruppenTabelle.ranglisteManuell}
                                            onRefresh={this.props.callBackRefresh}
                                            onGetSpielername={this.getSpielerName}
                                            onHide={() => this.setState({showProblemDialog:false})}/>

            }
        </div>
    }
}

Turniergruppe.propTypes = {
    tournament: PropTypes.object.isRequired,
    gruppenTabelle: PropTypes.object.isRequired,
    bestplatzierteDtos: PropTypes.array.isRequired,
    gruppennummer: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    zeigeTabelle: PropTypes.bool.isRequired,
    minimizeGroup: PropTypes.func.isRequired,
    showGroup: PropTypes.bool.isRequired,
    callBackRefresh: PropTypes.func.isRequired,
    readOnly : PropTypes.bool.isRequired,
    boardGames : PropTypes.array,
    liveGames : PropTypes.array,
    isSetModus : PropTypes.bool.isRequired,
    blendeGespielteSpieleAus : PropTypes.bool.isRequired,
    selectedPlayerId : PropTypes.string,
    onPlayerSelect : PropTypes.func.isRequired,
};

export default Turniergruppe
