import React from 'react'
import PropTypes from "prop-types";

import {ToggleButton} from 'primereact/togglebutton';
import {Card} from 'primereact/card';
import {InputSwitch} from 'primereact/inputswitch';
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import DateUtil from "../../../util/DateUtil";
import {Fieldset} from "primereact/fieldset";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

const INITIAL_STATE = {
    key: "",
    keyOld: null,
    keyGenerated: "",
    keyOldGenerated: "",
    keyOldExpired: "",
    shouldSendMailToAdmins : false,
    shouldSendMailToUsers : false,
    privatePublicKeySetting : false,
};

class SettingsPublicKey extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.generateKey = this.generateKey.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.loadData = this.loadData.bind(this);
        this.save = this.save.bind(this);
        this.savePrivateSettings = this.savePrivateSettings.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    loadData() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.user,
            json => {
                this.setState({...this.state, privatePublicKeySetting: json.privatePublicKeyInformationSetting});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/getpublickey",
            json => {
                this.setState({...this.state, ...json});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }


    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    savePrivateSettings() {
        this.showError(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.user + "/saveprivatepublickeysettings?privatePublicKeySetting=" + this.state.privatePublicKeySetting;
        let data = undefined;
        FetchUtil.fetchPost(url,
            data,
            () => {
                this.showInfo("Einstellungen wurde gespeichert!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    save() {
        this.showError(undefined);
        let data = {shouldSendMailToAdmins: this.state.shouldSendMailToAdmins, shouldSendMailToUsers: this.state.shouldSendMailToUsers};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.settings + "/savepublickeysettings",
            data,
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.setState(json);
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    generateKey() {
        this.showError(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.settings + "/generatepublickey",
            json => {
                this.showInfo("Generierung erfolgreich!");
                this.setState(json);
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        let formattedKeyDate = DateUtil.localDateTimeToString(this.state.keyGenerated);
        let formattedKeyOldDate = "";
        let formattedKeyOldExpiredDate = "";
        if( this.state.keyOld !== null) {
            formattedKeyOldDate = DateUtil.localDateTimeToString(this.state.keyOldGenerated);
            formattedKeyOldExpiredDate = DateUtil.localDateTimeToString(this.state.keyOldExpired);
        }

        return <div style={{textAlign:"left"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Fieldset legend="Hinweise..." style={{marginBottom:20}}>
                <div>- Mit dem Public Key kann die öffentliche Seite für die aktuell gültige Trainingssession geöffnet
                    werden.
                    In der Seite können die Ergebnisse eingetragen und der aktuelle Spielstand angeschaut werden.
                </div>
                <div>- Der Public Key wird vom System an jedem 1. des Monats automatisch geändert, damit ist der alte
                    Key nur noch für wenige Tage gültig.
                </div>
                <div>- Admins können den Public Key neu generieren.</div>
            </Fieldset>
            <Panel header="Public key" style={{textAlign: "left"}}>
                <div className="grid" style={{marginTop: "20px"}}>

                    <div className="col-12 md:col-12 lg:col-12">
                        <Card>
                            <div className="grid">
                                <div className="col">Persönliche Public Key Einstellungen!</div>
                            </div>
                            <div className="grid" style={{marginTop:"10px"}}>
                                <div className="col">Wähle nein, wenn du keine Mails bei Erneuerung vom Public Key erhalten möchtest, damit überschreibst du die Einstellung die der Admin global für das System gemacht hat.</div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width:"200px"}}>Public Key erhalten?</div>
                                <div className="col" style={{marginRight:"5px"}}>
                                    <ToggleButton onLabel="Ja" offLabel="Nein"
                                                    checked={this.state.privatePublicKeySetting}
                                                  onChange={(e) => this.setState({privatePublicKeySetting: e.value})} />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width: '200px', textAlign: "right"}}></div>
                                <div className="col"><Button label="Speichern"
                                                               onClick={() => this.savePrivateSettings()}/></div>
                            </div>

                        </Card>
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                        <Card style={{height:"180px"}}>
                            <div className="grid">
                                <div className="col-fixed" style={{width:"300px"}}>Mail bei Änderung des Keys an...</div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width:"120px"}}>... Admins:</div>
                                <div className="col" style={{marginRight:"5px"}}>
                                    <InputSwitch disabled={!this.props.isAdmin}  checked={this.state.shouldSendMailToAdmins} onChange={(e) => this.setState({shouldSendMailToAdmins: e.value})}/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width:"120px"}}>... alle Benutzer:</div>
                                <div className="col" style={{marginRight:"5px"}}>
                                    <InputSwitch disabled={!this.props.isAdmin} checked={this.state.shouldSendMailToUsers} onChange={(e) => this.setState({shouldSendMailToUsers: e.value})}/>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-fixed" style={{width: '120px', textAlign: "right"}}></div>
                                <div className="col"><Button disabled={!this.props.isAdmin} label="Speichern"
                                                               onClick={() => this.save()}/></div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-12 md:col-12 lg:col-6">
                        <Card style={{height:"180px"}}>
                            <div className="grid">
                                <div className="col-fixed" style={{width: '120px', textAlign: "right"}}>Aktueller Key:</div>
                                <div className="col"><b>{this.state.key}</b>{" - generiert am " + formattedKeyDate}</div>
                            </div>
                            {this.state.keyOld !== null && this.state.keyOld !== undefined ?
                                <div className="grid">
                                    <div className="col-fixed" style={{width: '120px', textAlign: "right"}}>Alter Key:</div>
                                    <div className="col">
                                        <b>{this.state.keyOld}</b>{" - generiert am " + formattedKeyOldDate + " - läuft ab am " + formattedKeyOldExpiredDate}
                                    </div>
                                </div>
                                :
                                <div/>
                            }
                            <div className="grid">
                                <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                                <div className="col"><Button disabled={!this.props.isAdmin} label="Key generieren"
                                                               onClick={() => this.generateKey()}/></div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Panel>
        </div>
    }
}

SettingsPublicKey.propTypes = {
    isAdmin: PropTypes.bool.isRequired

};

export default SettingsPublicKey;