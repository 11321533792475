import React from 'react'
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Fieldset} from "primereact/fieldset";
import DoppelpaarungSearch from "../liga/DoppelpaarungSearch";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class TurnierAddDoppelPlayerDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {doppelpaarung: undefined};

        this.save = this.save.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tournament === undefined && this.props.tournament !== undefined) {
            this.setState({tournament: this.props.tournament});
        }
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    showWarn(message) {
        this.setState({warnMessage: message});
    }

    save() {
        this.showError(undefined);
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/adddoppelplayeronrunningtournament/" + this.props.tournament.id + "/" + this.state.doppelpaarung.id,
            {},
            json => {
                this.showInfo("Hinzufügen war erfolgreich!");
                this.setState({user:undefined});
                this.props.callBackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler beim Speichern: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Speichern: " + error.message);
            }
        );
    }

    render() {
        const dialogFooter = (
            <div>
                <Button label="Doppelpaarung zur Liga/Turnier hinzufügen" disabled={this.state.doppelpaarung === undefined} icon="pi pi-save" onClick={() => this.save()}/>
            </div>
        );

        return <DHDialog onClose={() => {
            this.props.callBackDialogClose();
            this.setState({user:undefined});
        }} title="Doppelpaarung zum Turnier/Liga hinzufügen" show={this.props.visible}
                         buttons={dialogFooter}
                         showCloseButton={true}
                         comp={<>
                             <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                                 onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                                 onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                                 onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
                             <Fieldset legend="Hinweise..." style={{marginBottom:20}} toggleable={true}>
                                 <div>- Du kannst nur in eine Liga einfügen, wenn da gerade eine ungerade Teilnehmeranzahl vorhanden ist. Also bei 7,9,11 Teilnehmern, nicht bei 8,10,12 usw.</div>
                             </Fieldset>
                             <div className="grid">
                                 <div className="col-fixed" style={{width: 150}}>
                                     Doppelpaarung:
                                 </div>
                                 <div className="col-fixed" style={{width: 400}}>
                                     <DoppelpaarungSearch callBackInfo={(message) => this.showWarn(message)}
                                                          callBackError={(message) => this.showError(message)}
                                                          callBackClearMessages={() => this.showError(undefined)}
                                                          callBackUserFound={(doppelpaarung) => this.setState({doppelpaarung: doppelpaarung})}/>
                                 </div>
                             </div>
                         </>}/>
    }
}

TurnierAddDoppelPlayerDialog.propTypes = {
    callBackRefresh: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    tournament: PropTypes.object.isRequired,
};

export default TurnierAddDoppelPlayerDialog