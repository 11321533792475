import React from 'react'
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import {Fieldset} from "primereact/fieldset";
import ConfirmDialog from "../../general/ConfirmDialog";
import ConfigUtil from "../../../util/ConfigUtil";
import FetchUtil from "../../../util/FetchUtil";
import PlayerUtil from "../../../util/PlayerUtil";
import {NAVIGATION_ITEM} from "../../../constants/navigationItems";
import {NavLink} from "react-router-dom";
import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfirmWithQuestionDialog from "../../general/ConfirmWithQuestionDialog";
import Chat from "../../general/Chat";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

let stompClient = undefined;

class TrainingOnlineGame extends React.Component {

    constructor(props) {
        super(props);

        this.state = {teilnehmen: false, showTeilnehmenDialog: false, showDeleteTeilnahmeDialog:false, infos: undefined};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.websocketConnect = this.websocketConnect.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.loescheTeilnahmeAnOnlineSession = this.loescheTeilnahmeAnOnlineSession.bind(this);
    }

    componentDidMount() {
        this.websocketConnect();
    }

    componentWillUnmount() {
        if( stompClient !== undefined) {
            console.log("disconnect");
            stompClient.disconnect();
        }
    }

    websocketConnect() {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            () => {
                const destination = "/topic/public/training/onlinesession/infos/" + this.props.trainingssession.id + "/" + this.props.userid;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
            },
            () => {
                console.log("Websocket - connect error:");
            },
            () => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const body = JSON.parse(payload.body);

        let teilnehmen = false;
        // pruefen ob der aktuelle user an der onlinesession teilnimmt.
        if( body.players !== undefined && body.players !== null ) {
            for (const player of body.players) {
                if( player.player.id === this.props.userid) {
                    teilnehmen = true;
                }
            }
        }

        this.setState({teilnehmen: teilnehmen, infos: body});
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }


    loescheTeilnahmeAnOnlineSession() {
        this.showError(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.training + "/" + this.props.trainingssession.id + "/onlinesession/" + this.state.deleteOnlinePlayerId + "/remove";
                this.showInfo("Teilnahme an Onlinesession wurde beendet!");
        FetchUtil.fetchPost(url,
            {},
            () => {
                this.setState({showDeleteTeilnahmeDialog: false, deleteOnlinePlayerId: undefined});
                this.showInfo("Teilnahme an Onlinesession wurde beendet!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    speichereTeilnehmenAnOnlineSession() {
        this.showError(undefined);
        let url = ConfigUtil.getConfig().resourceUrls.training + "/" + this.props.trainingssession.id + "/onlinesession/add";
        FetchUtil.fetchPost(url,
            {},
            () => {
                this.setState({showTeilnehmenDialog: false});
                this.showInfo("Teilnahme an Onlinesession wurde gespeichert!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    getRowsTeilnahmenAberNichtGespielt() {

        let rows = [];
        if( this.state.infos === undefined) {
            return rows;
        }

        for (const player of this.state.infos.players) {

            let aktuellerStand = "";
            if( player.aktuellerGegener !== null ) {
                const gegenerName = PlayerUtil.anzeigeNameZumSpieler(player.aktuellerGegener);
                aktuellerStand = gegenerName + " - " + player.aktuellerStandMeineLegs + " : " + player.aktuellerStandGegnerLegs + " togo: " + player.aktuellerStandMeinToGo + "|" + player.aktuellerStandGegnerToGo;
                aktuellerStand = "( " + aktuellerStand + " )";
            }
            rows.push(<div key={"teilnehmer_spieler_" + player.player.id} className="grid align-center vertical-container" style={{textAlign: "left", height:40}}>
                <div className="col-fixed" style={{width:30, marginRight:10}}>
                    <img src="/images/delete_color.png" alt="Teilnehmer entfernen" style={{height:30, cursor: "pointer"}} onClick={() => this.setState({showDeleteTeilnahmeDialog: true, deleteOnlinePlayerId: player.player.id})}/>
                </div>
                <div className="col-fixed" style={{width:50}}>
                    <div className="box">{player.bereitsGegenMichGespielt && <img src="/images/check.png" alt="Gegen diesen Spieler hast du schon gespielt" style={{height:20, marginLeft:10, marginRight:10}}/> }</div>
                </div>
                <div className="col">
                    <div className="box">{PlayerUtil.anzeigeNameZumSpieler(player.player)}</div>
                </div>
            </div>
            )
        }
        return rows;
    }

    getAktuelleSpiele() {

        let rows = [];
        if( this.state.infos === undefined) {
            return rows;
        }

        let bereitsAngezeigtePlayer = [];
        for (const player of this.state.infos.players) {

            if( player.aktuellerGegener !== null ) {
                if( bereitsAngezeigtePlayer.includes(player.player.id) || bereitsAngezeigtePlayer.includes(player.aktuellerGegener.id)) {
                    continue;
                }
                bereitsAngezeigtePlayer.push(player.player.id);
                const player1 = PlayerUtil.anzeigeNameZumSpieler(player.player);
                const player2 = PlayerUtil.anzeigeNameZumSpieler(player.aktuellerGegener);
                const kastenHeader = <div className="grid" style={{width:300, backgroundColor:"#26a69a", color:"#f2f2f2"}}><div className="col">Spieler</div><div className="col-fixed" style={{width:50, textAlign:"right"}}>to go</div><div className="col-fixed" style={{width:40, textAlign:"right"}}>legs</div></div>;
                const kastenPlayer1 = <div className="grid" style={{width:300}}><div className="col">{player1}</div><div className="col-fixed" style={{width:50, textAlign:"right"}}>{player.aktuellerStandMeinToGo}</div><div className="col-fixed" style={{width:40, textAlign:"right"}}>{player.aktuellerStandMeineLegs}</div></div>;
                const kastenPlayer2 = <div className="grid" style={{width:300}}><div className="col">{player2}</div><div className="col-fixed" style={{width:50, textAlign:"right"}}>{player.aktuellerStandGegnerToGo}</div><div className="col-fixed" style={{width:40, textAlign:"right"}}>{player.aktuellerStandGegnerLegs}</div></div>;
                rows.push(<div key={"game_" + player.player.id} className="col-fixed" style={{width:300, padding:5, border:"1px solid gray", boxShadow:"0 2px 15px #888888", marginRight:10}}>{kastenHeader}{kastenPlayer1}{kastenPlayer2}</div>);
            }
        }
        return <div className="grid" style={{marginTop:20}}>{rows}</div>;
    }

    getHinweise() {
        return <Fieldset legend="Hinweise..." style={{marginBottom:20, marginTop:10}}>
            <div className="grid" style={{textAlign: "left"}}>
                - Sofern die Trainingssession online abgehalten wird, kannst du dich hier anmelden.
            </div>
            <div className="grid" style={{textAlign: "left", marginTop:10}}>
                - sobald ein Spiel für dich vorhanden ist, wird es dir angezeigt. Bitte beginne das Spiel dann umgehend.
            </div>
        </Fieldset>
    }

    protokollArea() {
        if( this.state.infos === undefined || this.state.infos === null || this.state.infos.protokoll === undefined || this.state.infos.protokoll === null || this.state.infos.protokoll.length === 0) {
            return "";
        }
        let rows =  [];
        for (const eintrag of this.state.infos.protokoll) {
            rows.push(<div>{eintrag}</div>);
        }
        return <div style={{marginTop:20, padding:0}}>
            <span style={{fontWeight:"bold"}}>Protokoll</span><br/>
            {rows}
        </div>
    }

    render() {
        const showGame = this.state.infos !== null && this.state.infos !== undefined && this.state.infos.myGameId !== null && this.state.infos.myGameId !== undefined;
        const rowsTeilnehmer = this.getRowsTeilnahmenAberNichtGespielt();
        const rowsAktuelleSpiele = this.getAktuelleSpiele();
        return <div style={{textAlign: "left", marginTop:10}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>

            <div className="grid">
                <div className="col-12 md:col-12 lg:col-6">
                    {this.getHinweise()}
                    {this.state.teilnehmen ?
                        <div className="grid" style={{textAlign: "left", marginTop:10, marginBottom:20}}>
                            <img src="/images/check.png" alt="Du bist in der Onlinesession angemeldet" style={{height:30, marginLeft:30, marginRight:10}}/>Du bist in dieser Onlinesession angemeldet.
                        </div>
                        :
                        <div className="grid" style={{textAlign: "left", marginTop:10}}>
                            Möchtest du an der Onlinetrainingsession teilnehmen:<Button label="An Onlinesession teilnehmen" onClick={() => this.setState({showTeilnehmenDialog: true})} style={{marginLeft: 50}}/>
                        </div>
                    }
                    { showGame ?
                        <div className="grid" style={{textAlign: "left", marginTop:10, color:"green", fontWeight:"bold"}}>
                            Für dich steht ein Spiel gegen '{this.state.infos.nameGegner}' an.<br/>Nach dem Klick auf den Button geht es los!!!
                            <NavLink to={NAVIGATION_ITEM.SCORING.route + "?gameid=" + this.state.infos.myGameId + "&playerid=" + this.state.infos.myPlayerId} style={{textDecoration: "none"}}>
                                <img src="/images/dart_select.png" style={{cursor:"pointer", width: 80, marginLeft:30}} alt=""/>
                            </NavLink>
                        </div>
                        :
                        <div className="grid" style={{textAlign: "left", marginTop:10, marginBottom:20}}>
                            Für dich steht aktuell kein Spiel an... Bitte behalte diesen Bereich im Auge, wir melden uns sobald du einen Gegner hast!
                        </div>
                    }
                    <div className="grid" style={{textAlign: "left", marginTop:10, marginBottom:10, fontWeight:"bold"}}>
                        Folgende Teilnehmer spielen diese Onlinetrainingsession
                    </div>
                    {rowsTeilnehmer}
                    {rowsAktuelleSpiele}
                    {this.protokollArea()}
                </div>
                <div className="col-12 md:col-12 lg:col-6">
                    <Fieldset legend="Chat" style={{marginBottom:20, marginTop:10}}>
                        <div style={{overflowY: "scroll", height:200}}>
                            <Chat type="trainingssession" id={this.props.trainingssession.id} userId={this.props.userid}
                                  loadData={false}
                                  callBackOnError={(message) => this.showError(message)}/>
                        </div>
                    </Fieldset>
                </div>
            </div>
            <ConfirmDialog visible={this.state.showTeilnehmenDialog}
                           callBackOnNein={() => this.setState({
                               showTeilnehmenDialog: false
                           })}
                           callBackOnJa={() => this.speichereTeilnehmenAnOnlineSession()}
                           header="Teilnahme Onlinesession"
                           textOben="Sobald du an der Onlinesession teilnimmst, kannst du diese Teilnahme nicht mehr beenden!!!"
                           textUnten="Möchtest du wirklich an der Onlinesession teilnehmen?"/>
            <ConfirmWithQuestionDialog visible={this.state.showDeleteTeilnahmeDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteTeilnahmeDialog: false
                           })}
                           callBackOnJa={() => this.loescheTeilnahmeAnOnlineSession()}
                           header="Teilnehmer an der Onlinesession entfernen"
                           textOben="Ein Teilnehmer an der Onlinesession sollte nicht gelöscht werden, nur im Notfall."
                           textUnten="Möchtest du die Person wirklich entfernen?"/>
        </div>
    }
}

TrainingOnlineGame.propTypes = {
    trainingssession: PropTypes.object.isRequired,
};

export default TrainingOnlineGame;
