import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import PropTypes from "prop-types";
import {Card} from "primereact/card";
import {ToggleButton} from "primereact/togglebutton";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class ProfilAllgemeineEinstellungenArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.saveScoringSettings = this.saveScoringSettings.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    saveSendEventMails() {
        this.showError(undefined);
        const newSendEventMails = !this.props.sendEventMails;
        let url = ConfigUtil.getConfig().resourceUrls.user + "/savesendeventmails?option=" + newSendEventMails;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveShowMeetingkey() {
        this.showError(undefined);
        const newShowMeetingkey = !this.props.showMeetingkey;
        let url = ConfigUtil.getConfig().resourceUrls.user + "/saveShowMeetingkey?option=" + newShowMeetingkey;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveShowScoringDoubleQuestion() {
        this.showError(undefined);
        const newShowScoringDoubleQuestion = !this.props.showScoringDoubleQuestion;
        let url = ConfigUtil.getConfig().resourceUrls.user + "/saveShowScoringDoubleQuestion?option=" + newShowScoringDoubleQuestion;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveNewsletter() {
        this.showError(undefined);
        const newNewsletter = !this.props.newsletter;
        let url = ConfigUtil.getConfig().resourceUrls.user + "/savenewsletter?option=" + newNewsletter;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    saveScoringSettings() {
        this.showError(undefined);
        const newScoringQuestionOffSetting = !this.props.scoringQuestionOffSetting;
        let url = ConfigUtil.getConfig().resourceUrls.user + "/savescoringsettings?option=" + newScoringQuestionOffSetting;
        FetchUtil.fetchPost(url,
            {},
            json => {
                this.showInfo("Einstellungen wurde gespeichert!");
                this.props.callbackRefresh();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        return <div className="ligashortoverview"
                    style={{height: "100%", borderLeft: "10px solid black", textAlign: "left"}}>
            <h3>Allgemeine Einstellungen</h3>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Card>
                <div className="grid">
                    <div className="col">Hier kannst du einstellen ob du Newsletter von Darthelfer erhalten möchtest.</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Sofern du 'JA' wählst, erhälst du ab und zu einen Newsletter. Keine Angst, wir werden dich nur selten kontaktieren.</div>
                </div>
                <div className="grid">
                    <div className="col" style={{marginRight:"5px"}}>
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.props.newsletter}
                                      onChange={(e) => this.saveNewsletter()} />
                    </div>
                </div>
            </Card>
            <Card style={{marginTop:10}}>
                <div className="grid">
                    <div className="col">Hier kannst du einstellen ob du beim Scoring in darthelfer.de beim 'finishen' die Frage nach der Anzahl der Würfe unterdrücken willst?</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Sofern du 'JA' wählst, wird beim 'finishen' immer 3 Darts für dich angenommen. Damit ist dein Average nicht ganz richtig. Du entscheidest... ;-)</div>
                </div>
                <div className="grid">
                    <div className="col" style={{marginRight:"5px"}}>
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.props.scoringQuestionOffSetting}
                                      onChange={(e) => this.saveScoringSettings()} />
                    </div>
                </div>
            </Card>
            <Card style={{marginTop:10}}>
                <div className="grid">
                    <div className="col">Hier kannst du einstellen ob du Emails erhalten möchtest, sobald ein Termin(Event) von dir von einer anderen Person angenommen oder storniert wird.</div>
                </div>
                <div className="grid">
                    <div className="col">Achtung! Es können viele Mails kommen, entscheide selbst ob das möchtest.</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Möchtest du eine Mail erhalten, wenn ein Event von dir angenommen oder storniert wird?</div>
                </div>
                <div className="grid">
                    <div className="col" style={{marginRight:"5px"}}>
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.props.sendEventMails}
                                      onChange={(e) => this.saveSendEventMails()} />
                    </div>
                </div>
            </Card>
            <Card style={{marginTop:10}}>
                <div className="grid">
                    <div className="col">Hier kannst du einstellen ob du beim Scoring in darthelfer.de beim 'finishen' deine Doppelquote erfassen willst?</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Sofern du 'JA' wählst, wird beim 'finishen' nach deine Doppelquote gefragt. Sei dir bewusst, das System lässt dich nicht in Ruhe Dart spielen. Bist du Statistiker oder Dartspieler? ;-)</div>
                </div>
                <div className="grid">
                    <div className="col" style={{marginRight:"5px"}}>
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.props.showScoringDoubleQuestion}
                                      onChange={(e) => this.saveShowScoringDoubleQuestion()} />
                    </div>
                </div>
            </Card>
            <Card style={{marginTop:10}}>
                <div className="grid">
                    <div className="col">Hier kannst du einstellen ob du in Ligen und Turnieren dem Admin den Meetingkey zeigen möchtest.</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Sofern du 'JA' wählst, kann ein Liga- o. Turnierleiter mit dem Meetingkey dein Scoring anschauen und sich in das Meeting einwählen. Damit sieht er die Kameras und kann die Boards sehen.</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Beide Spielen müssen zugestimmt haben.</div>
                </div>
                <div className="grid" style={{marginTop:"10px"}}>
                    <div className="col">Möchtest du den Meetingkey für deine Spiele freigeben?</div>
                </div>
                <div className="grid">
                    <div className="col" style={{marginRight:"5px"}}>
                        <ToggleButton onLabel="Ja" offLabel="Nein"
                                      checked={this.props.showMeetingkey}
                                      onChange={(e) => this.saveShowMeetingkey()} />
                    </div>
                </div>
            </Card>
        </div>
    }
}

ProfilAllgemeineEinstellungenArea.propTypes = {
    scoringQuestionOffSetting: PropTypes.bool.isRequired,
    sendEventMails: PropTypes.bool.isRequired,
    showScoringDoubleQuestion: PropTypes.bool.isRequired,
    newsletter: PropTypes.bool.isRequired,
    callbackRefresh: PropTypes.func.isRequired,
};

export default ProfilAllgemeineEinstellungenArea;
