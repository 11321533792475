import React from "react";
import PropTypes from "prop-types";
import {Button} from 'primereact/button';
import {InputText} from "primereact/inputtext";
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ConfirmDialog from "../../general/ConfirmDialog";
import DHDialog from "../../general/DHDialog";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class TournamentSponsorDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {tournamentSponsoren: [], sponsoren: [], anzeigename:"", url:"", image: "", showDeleteDialog: false, selectedSponsor: undefined};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.ladeAlleSponsoren = this.ladeAlleSponsoren.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tournament !== prevProps.tournament ) {
            this.ladeTurnierSponsoren();
        }
    }

    componentDidMount() {
        this.ladeAlleSponsoren();
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    showWarn(message) {
        this.setState({warnMessage: message});
    }

    ladeAlleSponsoren() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.sponsoren + "/all",
            json => {
                this.setState({sponsoren: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden: " + error.message);
            })
    }

    save() {
        this.showError(undefined);
        let data = {anzeigename: this.state.anzeigename, url: this.state.url, image: this.state.image};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.sponsoren + "/save",
            data,
            json => {
                this.showInfo("Daten wurden gespeichert!");
                this.setState({anzeigename:"", url:"", image: ""});
                this.ladeAlleSponsoren();
            },
            responseNotOk => {
                this.showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden: " + error.message);
            })
    }

    loeschen() {
        this.showError(undefined);
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.sponsoren + "/" + this.state.selectedSponsor.id,
            {},
            json => {
                this.showInfo("Daten wurden gelöscht!");
                this.setState({showDeleteDialog: false, selectedSponsor: undefined});
                this.ladeAlleSponsoren();
            },
            responseNotOk => {
                this.showError("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler beim Laden: " + error.message);
            })
    }

    render() {
        const footer = (
            <div>
                <Button label="Schliessen" icon="pi pi-times" onClick={() => this.props.callBackOnClose()} className="p-button-secondary" />
            </div>
        );

        let rows = [];
        for (const sponsor of this.state.sponsoren) {
            rows.push(<div key={"key_sponsor_" + sponsor.id}>
                <div style={{textAlign:"left", cursor:"pointer"}} onClick={() => this.changeState(sponsor.id)}>{sponsor.anzeigename}<br/>URL: {sponsor.url}<br/>Bild: {sponsor.image}</div>
                <Button label="Löschen" style={{marginTop:10}} onClick={() => this.setState({showDeleteDialog: true, selectedSponsor: sponsor})}/>
                <hr/>
            </div>);
        }

        return <DHDialog onClose={() => this.props.callBackOnClose()} title="Sponsoren verwalten" show={true} buttons={footer}
                         comp={<>
             <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                 onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                 onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                 onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
             <div style={{textAlign:"left", }}>
                <InputText placeholder="Anzeigename eingeben..." style={{width:"100%"}}
                           value={this.state.anzeigename}
                           onChange={(e) => this.setState({anzeigename: e.target.value})}/><br/>
                <InputText placeholder="URL eingeben - optional - (mit https://www) ..." style={{width:"100%", marginTop:5}}
                           value={this.state.url}
                           onChange={(e) => this.setState({url: e.target.value})}/><br/>
                <InputText placeholder="Link zu Bild - optional - (vollständig mit https://www) .." style={{width:"100%", marginTop:5}}
                           value={this.state.image}
                           onChange={(e) => this.setState({image: e.target.value})}/><br/>
                <Button label="Speichern" icon="pi pi-save"  style={{marginTop:5}} disabled={this.state.anzeigename === ""} onClick={() => this.save()} />
            </div>
            <div style={{textAlign:"left", marginTop:10, fontSize:12}}>
                Du siehst alle Sponsoren die zu dir als Liga-/Turnierbetreiber angelegt wurden.
            </div>
            <div style={{textAlign:"left", marginTop:10}}>
                {rows}
            </div>
            <ConfirmDialog visible={this.state.showDeleteDialog}
                           callBackOnNein={() => this.setState({
                               showDeleteDialog: false, selectedSponsor: undefined
                           })}
                           callBackOnJa={() => this.loeschen()}
                           header="Sponsor löschen"
                           textOben={"Sobald du den Sponsor löscht, wird dieser aus allen Turnieren entfernt."}
                           textUnten="Möchtest du die wirklich löschen?"/>
        </>}/>
    }

    valueChange(e, fieldName) {
        if( isNaN(e.target.value) ) {
            return;
        }
        this.setState({[fieldName]: e.target.value})
    }
}


TournamentSponsorDialog.propTypes = {
    callBackOnClose: PropTypes.func.isRequired,
};

export default TournamentSponsorDialog;
