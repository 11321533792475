import React, {useEffect, useState} from 'react'
import ErrorInfoComponent from "../general/ErrorInfoComponent";
import FetchUtil from "../../util/FetchUtil";
import ConfigUtil from "../../util/ConfigUtil";
import DHDefaultDrawer from "../general/DHDefaultDrawer";
import ConfirmWithQuestionDialog from "../general/ConfirmWithQuestionDialog";
import DHTextField from "../general/DHTextField";
import DHButtonSave from "../general/DHButtonSave";
import DHButtonIconDelete from "../general/DHButtonIconDelete";

export default function NuPlayerMatchComponent({eventOperatorId, player, onClose}) {

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const [playerMatches, setPlayerMatches] = useState([]);
    const [playerIntern, setPlayerIntern] = useState(player);

    const [externPersonDelete, setExternPersonDelete] = useState(undefined);

    const [darthelferSpielerId, setDarthelferSpielerId] = useState("");

    useEffect(() => {
        ladeDaten();
    }, []);

    const ladeDaten = () => {
        setErrorMessage(undefined);
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.nu + "/player/matches/"+ eventOperatorId,
            json => {
                setPlayerMatches(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler beim Laden: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler beim Laden: " + error.message);
            }
        );
    }

    const isPersonVerknuepft = () => {
        for (const playerMatch of playerMatches) {
            if( playerMatch.externPersonId === playerIntern.externPersonId ) {
                return true;
            }
        }
        return false;
    }

    const deleteVerknuepfung = () => {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.nu + "/player/match/eventoperator/" + eventOperatorId + "/delete/" + externPersonDelete.id,
            {},
            () => {
                setInfoMessage("Verknüpfung wurde gelöscht.");
                ladeDaten();
                setExternPersonDelete(undefined);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
                setExternPersonDelete(undefined);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
                setExternPersonDelete(undefined);
            }
        );
    }

    const save = () => {
        setErrorMessage(undefined);
        if( darthelferSpielerId.length !== 9 || !darthelferSpielerId.includes("U")) {
            setErrorMessage("Ungültige SpielerId, bitte Profil des Users prüfen.")
            return;
        }
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.nu + "/player/save/match/" + eventOperatorId + "/" + playerIntern.externPersonId + "/" + darthelferSpielerId,
            {personName: playerIntern.name},
            json => {
                setInfoMessage("Verbindund wurde erfolgreich gespeichert.")
                setPlayerIntern(undefined);
                ladeDaten();
            },
            responseNotOk => {
                setErrorMessage("Fehler:" + responseNotOk.message)
            },
            error => {
                setErrorMessage("Fehler:" + error.message)
            })
    }

    const getRows = () => {
        let rows = [];
        for (const playerMatch of playerMatches) {
            rows.push(<div className="grid" style={{marginBottom: 5}}>
                <div className="col-fixed" style={{width: 50}}>
                    {!playerMatch.confirmed && <DHButtonIconDelete onClick={() => setExternPersonDelete(playerMatch)}/>}
                </div>
                <div className="col">
                    <img src={playerMatch.confirmed ? "/images/dot_green.png" : "/images/dot_red.png"} style={{width: 15, marginRight: 5}}/>
                    {playerMatch.externPersonName}<br/>(PersondId: {playerMatch.externPersonId}/DH-SpielerId:{playerMatch.idShort})
                </div>
            </div>)
        }
        return rows;
    }

    const getLegendeArea = () => {
        return <div style={{marginTop: 10, fontSize: 11}}>
            <hr/>
            Grüner Punkt an der Zuordnung bedeutet: der User hat die Zuordnung akzeptiert.<br/><br/>
            Roter Punkt an der Zuordnung bedeutet: der User hat die Zuordnung noch nicht akzeptiert.<br/><br/>
            Ausschließlich durch den Darthelfer-User unbestätigte Verknüpfungen können von dir gelöscht werden. Hat der Darthelfer-User die Verknüpfung bestätigt, kann nur er diese Verknüpfung löschen.
        </div>
    }

    const getHinweisArea = () => {
        return <div style={{marginTop:20, fontSize:11}}>
            <strong>Hinweis:</strong><br/>
            Sofern du eine Verknüpfung einer nu-Person zu einem Darthelfer-User speicherst, erhält der entsprechende User eine Nachricht die er in
            seinem Profil bestätigen muss.<br/>
            Danach werden alle Ligaspiele des Users aus den Ligaspieltagen in seiner Gesamtstatistik erscheinen.<br/>
            Die darthelfer SpielerId muss ein 'U' beinhalten, nur so handelt es sich um einen Darthelfer User.
        </div>
    }
    const getNewMatchArea = () => {
        return <div>
            <div style={{marginBottom: 5, fontWeight:"bold"}}>
                nu-Person:
            </div>
            <div style={{marginBottom: 10}}>
                {player.name} ({player.externPersonId})
            </div>
            <div style={{marginBottom: 10}}>

            </div>
            <div style={{marginBottom: 10}}>
                <DHTextField id={"id-spielerid"} required={true} width="100%" label="darthelfer - SpielerId (siehe im Profil des Users)"
                             value={darthelferSpielerId} onChange={(text) => setDarthelferSpielerId(text)}/>
            </div>
            {isPersonVerknuepft() && <div style={{marginTop:20, marginBottom:5, color:"red"}}>Person ist bereits mit einem Darthelfer-User verknüpft.</div>}
            <div style={{marginBottom: 5}}>
                <DHButtonSave disabled={isPersonVerknuepft() || darthelferSpielerId === ""} working={false} onClick={() => save()}/>
            </div>
        </div>
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title={"Verknüpfung anzeigen/durchführen"} children={
        <div style={{minWidth: 350, padding: 20}}>
            <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />

            {playerIntern !== undefined && <>
                {getNewMatchArea()}
                {getHinweisArea()}
            </>
            }

            {playerMatches.length === 0 &&
                <div style={{marginTop: 30}}>
                    <hr/>
                    Aktuell sind keine Verknüpfungen von 'nu Personen' zu 'Darthelfer Usern' vorhanden.
                </div>
            }
            <div style={{marginTop:20}}>
                <div style={{fontWeight:"bold", marginBottom:5}}>Vorhanden nu-Person/Darthelfer-User Verknüpfungen</div>
                {getRows()}
            </div>
            {getLegendeArea()}
            {externPersonDelete &&
                <ConfirmWithQuestionDialog visible={true}
                                           callBackOnNein={() => setExternPersonDelete(undefined)}
                                           callBackOnJa={() => deleteVerknuepfung()}
                                           header="Verknüpfung löschen"
                                           textOben=""
                                           textUnten="Möchtest du die Verknüpfung wirklich löschen?"/>
            }
        </div>
    }/>
}