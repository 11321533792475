import React from 'react'
import {Button,} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function DHButtonProgress({label, icon, disabled, working, onClick, style}) {

    return <Button variant="contained" disabled={working || disabled}
                   onClick={onClick} startIcon={working ? <CircularProgress size={12} style={{color: 'white'}}/> : icon}
                   style={{textTransform: 'none', letterSpacing:"0.1em", ...style}}>{label}</Button>
}
