import React from 'react'
import PropTypes from "prop-types";

import {Panel} from 'primereact/panel';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

const INITIAL_STATE = {
    id: "",
    currentPassword: "",
    password: "",
    password2: ""
};


class SettingsUserdaten extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE, id: props.id};

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showInfo = this.showInfo.bind(this);
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    save() {
        this.showError(undefined);
        if( this.state.password !== this.state.password2) {
            this.showError("Die beiden neuen Passwörter stimmen nicht überein.");
            return;
        }
        let data = {currentPassword: this.state.currentPassword, newPassword: this.state.password};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.user,
            data,
            json => {
                this.showInfo("Speichern der neuen Zugangsdaten war erfolgreich!");
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        return <div>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <Panel header="Kennwort ändern" style={{textAlign: "left"}}>
                <div className="grid" style={{marginTop: "20px"}}>
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Aktuelles Passwort:</div>
                    <div className="col"><Password name="currentPassword" value={this.state.currentPassword}
                                                      onChange={(e) => this.handleChange(e)} feedback={false}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort:</div>
                    <div className="col"><Password name="password" value={this.state.password}
                                                     onChange={(e) => this.handleChange(e)}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}>Kennwort bestätigen:</div>
                    <div className="col"><Password name="password2" value={this.state.password2}
                                                     onChange={(e) => this.handleChange(e)}/></div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={{width: '180px', textAlign: "right"}}></div>
                    <div className="col"><Button label="Speichern" onClick={() => this.save()}/></div>
                </div>
            </Panel>
        </div>
    }
}

SettingsUserdaten.propTypes = {
    id : PropTypes.string.isRequired
};

export default SettingsUserdaten;