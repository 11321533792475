import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function DHDatePicker({label, value, changeValue, disabled}) {

    const [myValue, setMyValue] = useState(null);

    useEffect(() => {
        if (value === undefined) {
            return;
        }
        setMyValue(dayjs(value));
    }, [value]);

    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
        <DatePicker
            label={label}
            value={myValue}
            onError={(e) => {
                if (e) {
                    changeValue(undefined);
                }
            }}
            onChange={(e) => {
                if (e) {
                    let formattedValue = e.format('YYYY-MM-DD');
                    formattedValue += "T00:00:00.000Z"
                    changeValue(formattedValue);
                } else {
                    changeValue(undefined);
                }
            }}
            slotProps={{
                actionBar: {
                    actions: ['accept', 'clear', 'cancel']
                },
                textField: {size: 'small', placeholder: ''}
            }}
            disabled={disabled}
        />
    </LocalizationProvider>
}