import React from 'react'
import {TournamentShortComponent} from "./ligaComponents";
import ChoosLeagueoperatorContainer from "../../../containers/views/liga/ChoosLeagueoperatorContainer";
import PropTypes from "prop-types";

class TurnierShortOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showAktive: true,
            showNochZurAnmeldung: true,
            showAlleDieAktuellGespieltWerden: false,
            showLeagoperatorDialog: false
        };
    }

    render() {
        return <div className="col-12 md:col-12 lg:col-5" style={{padding:10}}>
            <div className="ligashortoverview" style={{height:"100%", borderLeft:"10px solid #0497ae"}}>
                <ChoosLeagueoperatorContainer visible={this.state.showLeagoperatorDialog} closeDialog={() => this.setState({showLeagoperatorDialog:false})}/>
                <div className="grid">
                    <div className="col"><h3>Turnierübersicht</h3></div>
                    <div className="col-fixed" style={{width:60}}><img src="/images/auswahl_black2.png" alt="Auswahl Turnierbetreiber" style={{height:40, marginLeft:10}} onClick={() => this.setState({showLeagoperatorDialog: true})}/></div>
                </div>
                <div style={{marginLeft:10}}>
                    <h4>Turniere in denen du aktiv bist
                        <i className={this.state.showAktive ? "pi pi-window-minimize" : "pi pi-window-maximize"} style={{marginLeft:5}} onClick={() => this.setState({showAktive: !this.state.showAktive})}/>
                    </h4>
                    {this.state.showAktive && <TournamentShortComponent tournaments={this.props.turnierInfos.alleAktivenZumUser} showDetails={true}/>}
                </div>
                <div style={{marginLeft:10}}>
                    <h4>Turniere für die du dich noch anmelden kannst
                        <i className={this.state.showNochZurAnmeldung ? "pi pi-window-minimize" : "pi pi-window-maximize"} style={{marginLeft:5}} onClick={() => this.setState({showNochZurAnmeldung: !this.state.showNochZurAnmeldung})}/>
                    </h4>
                    {this.state.showNochZurAnmeldung && <TournamentShortComponent tournaments={this.props.turnierInfos.alleRegistrierungMoeglich} showDetails={true}/>}
                </div>
                <div style={{marginLeft:10}}>
                    <h4>Alle Turniere die aktuell gespielt werden
                        <i className={this.state.showAlleDieAktuellGespieltWerden ? "pi pi-window-minimize" : "pi pi-window-maximize"} style={{marginLeft:5}} onClick={() => this.setState({showAlleDieAktuellGespieltWerden: !this.state.showAlleDieAktuellGespieltWerden})}/>
                    </h4>
                    {this.state.showAlleDieAktuellGespieltWerden && <TournamentShortComponent tournaments={this.props.turnierInfos.alleGestarteten} showDetails={true}/>}
                </div>
            </div>
        </div>
    }
}

TurnierShortOverview.propTypes = {
    turnierInfos: PropTypes.any,
}

export default TurnierShortOverview;
