import React, {useState} from 'react'
import ErrorInfoComponent from "../../../general/ErrorInfoComponent";
import FetchUtil from "../../../../util/FetchUtil";
import ConfigUtil from "../../../../util/ConfigUtil";
import {TOURNAMENTSYSTEMS} from "../../../../constants/tournamentSystems";
import DHDateTimePicker from "../../../general/DHDateTimePicker";
import DHDefaultDrawer from "../../../general/DHDefaultDrawer";
import DHTextField from "../../../general/DHTextField";
import DHButtonSave from "../../../general/DHButtonSave";


export default function TournamentCopySidebar({tournamentId, turniersystem, propMannschaftHeim, propBezeichnung, propBezeichnungKurz, onCopy, onClose}) {

    const [newId, setNewId] = useState(undefined);
    const [beginn, setBeginn] = useState(undefined);
    const [bezeichnung, setBezeichnung] = useState("[COPY] - " + propBezeichnung);
    const [bezeichnungKurz, setBezeichnungKurz] = useState(propBezeichnungKurz !== undefined && propBezeichnungKurz !== null && propBezeichnungKurz !== "" ? "[COPY] - " + propBezeichnungKurz : "");
    const [mannschaftHeim, setMannschaftHeim] = useState(propMannschaftHeim !== undefined && propMannschaftHeim !== null ? propMannschaftHeim : "");
    const [mannschaftGast, setMannschaftGast] = useState("");

    const [infoMessage, setInfoMessage] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const copyTournament = () => {
        setErrorMessage(undefined);
        let tmpBezeichnung = bezeichnung;
        if( isLigaspieltag() ) {
            tmpBezeichnung = mannschaftHeim + " vs. " + mannschaftGast;
        }
        let data = {bezeichnung:tmpBezeichnung, bezeichnungShort: bezeichnungKurz, mannschaftHeim: mannschaftHeim, mannschaftGast: mannschaftGast, beginn: beginn};
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.tournament + "/copy/" + tournamentId,
            data,
            json => {
                setInfoMessage("Turnier wurde erfolgreich kopiert. Du kannst direkt mit dem nächsten Kopieren weiter machen.");
                onCopy(json);
            },
            responseNotOk => {
                setErrorMessage("Fehler: " + responseNotOk.message);
            },
            error => {
                setErrorMessage("Fehler: " + error.message);
            }
        );
    }

    const isLigaspieltag = () => {
        return turniersystem === TOURNAMENTSYSTEMS.LIGASPIELTAG.id;
    }

    const showSaveButton = () => {
        if( isLigaspieltag()) {
            return !mannschaftHeim || !mannschaftGast || !beginn;
        } else {
            return !bezeichnung || !beginn;
        }
    }

    return <DHDefaultDrawer onClose={() => onClose()} show={true} title="Event kopieren" children={<>
        <ErrorInfoComponent infoMessage={infoMessage} errorMessage={errorMessage} onClearInfoMessage={() => setInfoMessage(undefined)} onClearErrorMessage={() => setErrorMessage(undefined)} />
        <div style={{padding:20}}>
            <div style={{marginBottom: 10}}>
                Das kopierte Event wird genau mit den gleichen Daten angelegt und kann danach
                geändert werden.
            </div>
            <div style={{marginBottom: 10}}>
                Bitte wähle hier die neue Bezeichnung und den Startzeitpunkt.
            </div>
            {isLigaspieltag() && <>
                <div style={{marginBottom: 10}}>
                    <DHTextField id="id-mannschaftheim" label="Name Heimmannschaft" width="100%" required={true} value={mannschaftHeim} onChange={(text) => setMannschaftHeim(text)}/>
                </div>
                <div style={{marginBottom: 10}}>
                    <DHTextField id="id-mannschaftgast" label="Name Gastmannschaft" width="100%" required={true} value={mannschaftGast} onChange={(text) => setMannschaftGast(text)}/>
                </div>
            </>}
            {!isLigaspieltag() && <>
                <div style={{marginBottom: 10}}>
                    <DHTextField id="id-bezeichnung" label="Bezeichnung" width="100%" required={true} value={bezeichnung} onChange={(text) => setBezeichnung(text)}/>
                </div>
                <div style={{marginBottom: 10}}>
                    <DHTextField id="id-bezeichnung-kurz" label="Bezeichnung (kurz):" width="100%" required={false} value={bezeichnungKurz} onChange={(text) => setBezeichnungKurz(text)}/>
                </div>
            </>
            }
            <div style={{marginTop:20, marginBottom: 20}}>
                <DHDateTimePicker label="Startzeitpunkt" disabled={false} value={beginn} changeValue={setBeginn}/>
            </div>
            <div style={{marginBottom: 20}}>
                Möchtest du das Event wirklich kopieren?
            </div>
            <div>
                <DHButtonSave working={false} disabled={showSaveButton()} onClick={() => copyTournament()}/>
            </div>
        </div>
    </>}/>
}
