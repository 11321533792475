import React from 'react'
import FetchUtil from "../../../util/FetchUtil";
import ConfigUtil from "../../../util/ConfigUtil";
import {Card} from "primereact/card";
import {TabPanel, TabView} from "primereact/tabview";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";
import {ToggleButton} from "primereact/togglebutton";
import AdministrationZuschauenGames from "./AdministrationZuschauenGames";
import AdminLigabetreiber from "./AdminLigabetreiber";
import {AdministrationLigaProAccountVergeben} from "./AdministrationLigaProAccountVergeben";
import {AdministrationTournament} from "./AdministrationTournament";
import {AdministrationNuAnbindung} from "./AdministrationNuAnbindung";
import AdminVeranstalter from "./AdminVeranstalter";
import DHDatePicker from "../../general/DHDatePicker";
import ErrorInfoComponent from "../../general/ErrorInfoComponent";

class Administration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.ladeAdminNotificationsDaten = this.ladeAdminNotificationsDaten.bind(this);
        this.ladeGlobalSettings = this.ladeGlobalSettings.bind(this);
        this.saveNotification = this.saveNotification.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
        this.saveGlobalSetting = this.saveGlobalSetting.bind(this);
        this.sendSystemGC = this.sendSystemGC.bind(this);
    }

    componentDidMount() {
        this.ladeAdminNotificationsDaten();
        this.ladeGlobalSettings();
    }

    saveNotification() {
        let data = {text: this.state.notificationText, anzeigeVon: this.state.notificationVon, anzeigeBis: this.state.notificationBis, color: this.state.notificationcolor};
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.admin + "/putNotification",
            data,
            () => {
                this.setState({notificationText:"", notificationVon: undefined, notificationBis: undefined,notificationcolor:undefined});
                this.ladeAdminNotificationsDaten();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

    }

    sendSystemGC() {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/gc",
            {},
            () => {
                this.showInfo("System.gc() wurde ausgeführt.")
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

    }

    saveGlobalSetting(type, value) {
        FetchUtil.fetchPost(ConfigUtil.getConfig().resourceUrls.admin + "/saveGlobalsettings?type=" + type +"&value=" + value,
            {},
            () => {
                this.ladeGlobalSettings();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

    }

    deleteNotification(id) {
        FetchUtil.fetchDelete(ConfigUtil.getConfig().resourceUrls.admin + "/deleteNotification/" + id,
            {},
            () => {
                    this.ladeAdminNotificationsDaten();
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );

    }

    ladeGlobalSettings() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.admin + "/loadGlobalsettings",
            json => {
                this.setState({...json});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    ladeAdminNotificationsDaten() {
        FetchUtil.fetchGet(ConfigUtil.getConfig().resourceUrls.admin + "/adminnotifications?all=true",
            json => {
                this.setState({adminnotifcations: [...json]});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    render() {
        return <div
            style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            <div className="pageHeader" style={{backgroundColor:"green"}}>
                Administration
            </div>
            <TabView style={{width: "100%"}}>
                <TabPanel header="Adminmeldungen">
                    {this.getAdminmeldungenEdit()}
                    {this.getAdminmeldungen()}
                </TabPanel>
                <TabPanel header="Globale Einstellungen">
                    {this.getGlobaleEinstellungen()}
                </TabPanel>
                <TabPanel header="Zuschauen Games">
                    <AdministrationZuschauenGames/>
                </TabPanel>
                <TabPanel header="Liga-Turnierbetreiber">
                    <AdminLigabetreiber/>
                </TabPanel>
                <TabPanel header="Veranstalter">
                    <AdminVeranstalter/>
                </TabPanel>
                <TabPanel header="Turnier">
                    <AdministrationTournament/>
                </TabPanel>
                <TabPanel header="nu-Anbindung">
                    <AdministrationNuAnbindung/>
                </TabPanel>
                <TabPanel header="Liga Proaccount vergeben">
                    <AdministrationLigaProAccountVergeben/>
                </TabPanel>
                <TabPanel header="System">
                    {this.getSystemArea()}
                </TabPanel>
            </TabView>
        </div>
    }

    getAdminmeldungenEdit() {
        return <Card style={{padding: 20}}>
            <div className="grid">
                <InputText style={{width: "100%"}} value={this.state.notificationText}
                           placeholder="Nachricht"
                           onChange={(e) => this.setState({notificationText: e.target.value})}/>
            </div>
            <div className="grid" style={{marginTop:20, textAlign:"left"}}>
                <div className="col">
                    <span style={{marginRight:20}}>Anzeigefarbe:</span>
                    <RadioButton inputId="chb-notificationcolornormal" name="notificationcolor"
                                 value="normal" onChange={(e) => this.setState({notificationcolor: e.value})} checked={this.state.notificationcolor === 'normal'} />
                    <label htmlFor="chb-notificationcolornormal" className="p-checkbox-label" style={{marginRight:10}}>normal</label>
                    <RadioButton inputId="chb-notificationcolorred" name="notificationcolor"
                                 value="red" onChange={(e) => this.setState({notificationcolor: e.value})} checked={this.state.notificationcolor === 'red'} />
                    <label htmlFor="chb-notificationcolorred" className="p-checkbox-label" style={{marginRight:10}}>rot</label>
                    <RadioButton inputId="chb-notificationcolorgreen" name="notificationcolor"
                                 value="green" onChange={(e) => this.setState({notificationcolor: e.value})} checked={this.state.notificationcolor === 'green'} />
                    <label htmlFor="chb-notificationcolorgreen" className="p-checkboredx-label">grün</label>
                </div>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:5}}>
                <div className="col-fixed" style={{width:150}}>
                    Anzeige von:
                </div>
                <DHDatePicker label="" value={this.state.notificationVon} changeValue={(date) => this.setState({notificationVon: date})}/>
            </div>
            <div className="grid" style={{textAlign:"left", marginTop:5}}>
                <div className="col-fixed" style={{width:150}}>
                    Anzeige bis:
                </div>
                <DHDatePicker label="" value={this.state.notificationBis} changeValue={(date) => this.setState({notificationBis: date})}/>
            </div>
            <div className="grid" style={{marginTop:10}}>
                <Button label="Speichern" disabled={!this.state.notificationText || !this.state.notificationVon || !this.state.notificationBis} onClick={() => this.saveNotification()}/>
            </div>
        </Card>

    }

    getAdminmeldungen() {
        return <div>
            <div className="grid" style={{padding: 20}}>
                Hier werden Systemmeldungen zur Anzeige auf dem Dashboard angezeigt.
            </div>
            {this.getAdmimMeldungenRows()}
        </div>

    }

    getSystemArea() {
        return <div style={{padding: 20}}>
            <div className="grid" style={{marginTop: 20}}>
                <Button label="System.gc() auslösen"
                              onClick={() => this.sendSystemGC()}
                              style={{marginRight:10}}/>
                Achtung! Diese Aktion kann stressig für das System sein.
            </div>
        </div>
    }

    getGlobaleEinstellungen() {
        return <div style={{padding: 20}}>
            <div className="grid">
                Hier kannst du globale Systemeinstellungen vornehmen.
            </div>
            <div className="grid" style={{marginTop: 20}}>
                <ToggleButton checked={this.state.sendMails} onLabel="Ja" offLabel="Nein"
                              onChange={(e) => this.saveGlobalSetting("sendMails",  e.value)}
                style={{marginRight:10}}/>
                Emailversand im System erlaubt
            </div>
            <div className="grid" style={{marginTop: 20}}>
                <ToggleButton checked={this.state.registrierungErlaubt} onLabel="Ja" offLabel="Nein"
                              onChange={(e) => this.saveGlobalSetting("registrierungErlaubt",  e.value)}
                              style={{marginRight:10}}/>
                Registrierung im System erlaubt.
            </div>
        </div>
    }

    getAdmimMeldungenRows() {
        if (this.state.adminnotifcations === null || this.state.adminnotifcations === undefined || this.state.adminnotifcations.length === 0) {
            return <div><br/><br/>Keine Daten vorhanden</div>;
        }

        let rows = [];
        for (const adminnotifcation of this.state.adminnotifcations) {
            rows.push(<div key={"key_admin_notificaion" + adminnotifcation.id}>
                {adminnotifcation.text}<br/>
                Von: {adminnotifcation.anzeigeVon}<br/>
                Bis: {adminnotifcation.anzeigeBis}<br/>
                Farbe: {adminnotifcation.color}<br/>
                <Button label="Löschen" onClick={() => this.deleteNotification(adminnotifcation.id)}/>
                <hr/>
            </div>)
        }
        return rows;

    }
}

Administration.propTypes = {};

export default Administration
