import React from 'react'

import {TabPanel, TabView} from 'primereact/tabview';
import SettingsUserdaten from "./SettingsUserdaten";
import SettingsAdmins from "./SettingsAdmins";
import SettingsTeams from "./SettingsTeams";
import SettingsTrainingssessions from "./SettingsTrainingssessions";
import SettingsSaisonen from "./SettingsSaisonen";
import SettingsPublicKey from "./SettingsPublicKey";
import {USER_ROLES} from "../../../constants/userRoles";
import PropTypes from "prop-types";


const INITIAL_STATE = {
    id: "",
    name: "",
    firstname: "",
    nickname: "",
    email: "",
    mobilenumber: "",
    username: ""
};


class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    render() {
        let isSuperadmin = false;
        let isAdmin = false;
        let tabs = [];
        if (this.props.userRoles !== undefined && this.props.userRoles !== null) {
            isSuperadmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.SUPERADMIN.text);
            isAdmin = this.props.userRoles.some(userRole => userRole === USER_ROLES.ADMINISTRATOR.text);
            if (isSuperadmin || isAdmin) {
                tabs.push(<TabPanel header="Trainingssessions"
                                    key="trainingssessions"><SettingsTrainingssessions/></TabPanel>);
                tabs.push(<TabPanel header="Admins bestimmen" key="adminsbestimmen">
                    <SettingsAdmins id={this.props.id} userRoles={this.props.userRoles}/></TabPanel>);
                tabs.push(<TabPanel header="Mannschaften" key="mannschaften">
                    <SettingsTeams id={this.props.id} userRoles={this.props.userRoles}/></TabPanel>);
                tabs.push(<TabPanel header="Saisonen" key="sessions"><SettingsSaisonen/></TabPanel>);
            }

        }
        return <div style={{textAlign: "center", align: "center", backgroundColor: "white", color: "black"}}>
            <div className="pageHeader" style={{backgroundColor: "#e0e0eb"}}>
                Einstellungen
            </div>
            <div className="grid" style={{padding: 20, marginLeft: 0, marginRight: 0}}>
                <TabView style={{width:"100%",paddingTop:10, paddingLeft:10, paddingRight:10}}>
                    <TabPanel key="benutzerdatenaendern" header="Benutzerdaten ändern">
                        <SettingsUserdaten id={this.props.id}/>
                    </TabPanel>
                    <TabPanel key="publickey" header="Public Key">
                        <SettingsPublicKey isAdmin={isAdmin || isSuperadmin}/>
                    </TabPanel>
                    {tabs}
                </TabView>
            </div>
        </div>

    }
}

Settings.propTypes = {
    id: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default Settings;