import React from 'react';
import PropTypes from "prop-types";

import {Stomp} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import ConfigUtil from "../../util/ConfigUtil";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FetchUtil from "../../util/FetchUtil";
import {InputAreaSmall, PlayerSingleArea, PlayerSingleAreaSmall, ScoreArea, ScoreHeader, StatistikArea, WurfArea} from "./ScoreComponents";
import {NavLink} from "react-router-dom";
import {NAVIGATION_ITEM} from "../../constants/navigationItems";
import AnzahlWuerfeDialog from "../views/training/AnzahlWuerfeDialog"
import AufnahmeShortcutDialog from "../views/training/AufnahmeShortcutDialog";
import {getModusNameById} from "../general/GameModusComponent";
import ErrorInfoComponent from "../general/ErrorInfoComponent";

let stompClient = undefined;

class TwoPlayerScoringView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gameid: props.gameid,
            aufnahme: "",
            saving: false,
            showAufnahmeShortcutsDialog: false,
            showAnzahlWuerfe: false,
        };

        this.showInfo = this.showInfo.bind(this);
        this.showError = this.showError.bind(this);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.sendAnzahlWuerfe = this.sendAnzahlWuerfe.bind(this);
        this.merkeAufnahme = this.merkeAufnahme.bind(this);
        this.sendAufnahme = this.sendAufnahme.bind(this);
        this.saveAufnahme = this.saveAufnahme.bind(this);
        this.changeScore = this.changeScore.bind(this);
    }

    componentDidMount() {
        this.websocketConnect(this.state.gameid);
    }

    componentWillUnmount() {
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
    }

    isMobileDevice() {
        let windowWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        if (windowWidth < 1200) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // showAnzahlWuerfe weil dadurch ein fenster geöffnet wird, welches auf jeden fall den focus erhalten soll.
        if (this.aufnahmeInput !== null && this.aufnahmeInput !== undefined && !this.state.showAnzahlWuerfe) {
            this.aufnahmeInput.focus();
        }
    }

    websocketConnect(gameid) {
        stompClient = Stomp.over(function () {
            return new SockJS(ConfigUtil.getConfig().websocketUrl);
        });
        stompClient.reconnect_delay = 10000;
        // stompClient.debug = function (str) {}; // Disable debug logging
        stompClient.connect({},
            (x) => {
                const destination = "/topic/public/game/x01/" + gameid;
                console.log("Websocket - connect success");
                console.log("subscribe to " + destination);
                stompClient.subscribe(destination, this.onMessageReceived);
            },
            (x) => {
                console.log("Websocket - connect error:");
            },
            (x) => {
                console.log("Websocket - disconnect:");
            });
    }

    onMessageReceived(payload) {
        const json = JSON.parse(payload.body);
        this.setState({
            ...json
        });
    }

    showInfo(message) {
        this.setState({infoMessage: message});
    }

    showError(message) {
        this.setState({errorMessage: message});
    }

    handelKeyDown(event) {
        if (event.keyCode === 13) {
            // enter
            this.sendAufnahme();
            return;
        }
        let acitvePlayer = this.getActivePlayer();
        const wertFuerF9 = this.getToGo(acitvePlayer) - this.state.aufnahme;
        switch (event.key) {
            case "F1":
                this.sendAufnahme(0);
                event.preventDefault();
                break;
            case "F2":
                this.sendAufnahme(26);
                event.preventDefault();
                break;
            case "F3":
                this.sendAufnahme(41);
                event.preventDefault();
                break;
            case "F4":
                this.sendAufnahme(45);
                event.preventDefault();
                break;
            case "F5":
                this.sendAufnahme(60);
                event.preventDefault();
                break;
            case "F6":
                this.sendAufnahme(81);
                event.preventDefault();
                break;
            case "F7":
                this.sendAufnahme(85);
                event.preventDefault();
                break;
            case "F8":
                this.sendAufnahme(100);
                event.preventDefault();
                break;
            case "F9":
                this.sendAufnahme(wertFuerF9);
                event.preventDefault();
                break;
            case "F10":
                this.sendAufnahme(this.getToGo(this.getActivePlayer()), 1);
                event.preventDefault();
                break;
            case "F11":
                this.sendAufnahme(this.getToGo(this.getActivePlayer()), 2);
                event.preventDefault();
                break;
            case "F12":
                this.sendAufnahme(this.getToGo(this.getActivePlayer()), 3);
                event.preventDefault();
                break;
            default:
                break;
        }
        event.stopPropagation();
    }

    merkeAufnahme(aufnahme) {
        this.setState({aufnahme: aufnahme});
    }

    sendAnzahlWuerfe(anz) {
        this.sendAufnahme(this.state.aufnahme, anz);
    }

    sendAufnahme(aufnahme, anzahlWuerfe) {
        let tmpAufnahme;
        if (aufnahme !== undefined && aufnahme !== null) {
            tmpAufnahme = aufnahme;
        } else {
            tmpAufnahme = this.state.aufnahme;
        }
        this.saveAufnahme(tmpAufnahme, anzahlWuerfe);
    }

    getActivePlayer() {
        for (const player of this.state.players) {
            if( player.active) {
                return player;
            }
        }
        return undefined;
    }

    saveAufnahme(aufnahme, anzahlWuerfe) {
        this.showError(undefined);
        if (this.state.saving) {
            console.log("Ich speichere gerade, weiterer Speicherversuch nicht erlaubt.");
            return;
        }
        const aufnahmeKorrekt = aufnahme !== "" && parseInt(aufnahme) <= 180;
        if (!aufnahmeKorrekt) {
            return;
        }

        let activePlayer = this.getActivePlayer();
        const showScoringQuestion = activePlayer.showScoringQuestion;
        const togo = this.getToGo(activePlayer);
        if (showScoringQuestion && togo === parseInt(aufnahme) && anzahlWuerfe === undefined) {
            this.setState({showAnzahlWuerfe: true});
            return;
        }

        this.setState({saving: true});

        let timeout = 5000;
        if (this.props.timeout) {
            timeout = this.props.timeout;
        }
        let activeplayerid = activePlayer.playerid
        let data = {scoreDto: {aufnahme: aufnahme, anzahlWuerfe: anzahlWuerfe}};
        const url = ConfigUtil.getConfig().resourceUrls.score + "/games/" + this.state.gameid + "/" + activeplayerid + "/score";

        FetchUtil.fetchPost(url,
            data,
            () => {
                this.setState({aufnahme: "", saving: false});
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
                this.setState({saving: false});
            },
            error => {
                this.showError("Fehler: " + error.message);
                this.setState({saving: false});
            }, timeout
        );
    }

    changeScore(playerid, scoreid, aufnahme) {
        FetchUtil.fetchPut(ConfigUtil.getConfig().resourceUrls.score + "/games/" + this.state.gameid + "/" + playerid + "/" + scoreid + "/score?aufnahme=" + aufnahme,
            {},
            json => {
            },
            responseNotOk => {
                this.showError("Fehler: " + responseNotOk.message);
            },
            error => {
                this.showError("Fehler: " + error.message);
            }
        );
    }

    render() {
        if (this.state.players === undefined) {
            return <div>Dein Spiel ist noch nicht geladen...</div>;
        }

        let existiertEinGewinner = this.existiertEinGewinner();
        const anzScoresPlayer1 = existiertEinGewinner ? 0 : this.getGameDetails(this.state.players[0]).scoreDtos.length;
        const anzScoresPlayer2 = existiertEinGewinner ? 0 : this.getGameDetails(this.state.players[1]).scoreDtos.length;
        const anzScores = anzScoresPlayer1 > anzScoresPlayer2 ? anzScoresPlayer1 : anzScoresPlayer2;
        if( this.isMobileDevice()) {
            return <div style={{width:"100%", height:"100%", backgroundColor: "#e6f2ff", padding:0, margin:0}}>
                <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                    onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                    onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                    onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
                <div style={{padding:5,fontSize:14}}>Modus: {getModusNameById(this.state.modusId)} - 'first to legs': {this.state.firstToLegs}</div>
                <div className="grid" style={{padding:0,margin:0, marginBottom:2}}>
                    <Button icon="pi pi-times" style={{width: 40, height: "100%"}} onClick={() => this.setState({aufnahme:""})} disabled={this.state.aufnahme === ""}/>
                    <Button icon="pi pi-angle-left" style={{width: 40, height: "100%"}} onClick={() => this.setState({aufnahme: this.state.aufnahme.substring(0, this.state.aufnahme.length -1)})} disabled={this.state.aufnahme === ""}/>
                    <span style={{marginLeft:30, marginTop:0, fontSize:28, fontWeight:"bold", width:50}}>{this.state.aufnahme}</span>
                </div>
                <InputAreaSmall merkeAufnahme={this.merkeAufnahme} sendAufnahme={this.sendAufnahme} aufnahme={this.state.aufnahme}/>
                <div className="grid" style={{width: "100%", padding:0,margin:0}}>
                    <PlayerSingleAreaSmall player={this.state.players[0]}
                                           togo={this.getToGo(this.state.players[0])} isLegBeginner={this.state.beginnerLegPlayerid === this.state.players[0].playerid}/>
                    <PlayerSingleAreaSmall player={this.state.players[1]}
                                           togo={this.getToGo(this.state.players[1])} isLegBeginner={this.state.beginnerLegPlayerid === this.state.players[1].playerid}/>
                </div>
                {!existiertEinGewinner ?
                    this.getScoreArea(anzScores)
                    :
                    this.gameOverAreaSmall()
                }
                <AnzahlWuerfeDialog visible={this.state.showAnzahlWuerfe} sendAnzahlWuerfe={this.sendAnzahlWuerfe} callBackOnClose={() => this.setState({showAnzahlWuerfe:false})}/>
            </div>;
        }

        return <div
            style={{backgroundColor: "#e6f2ff", height: "100%", width: "100%"}}>
            <ErrorInfoComponent errorMessage={this.state.errorMessage} infoMessage={this.state.infoMessage} warnMessage={this.state.warnMessage}
                                onClearInfoMessage={() => this.setState({infoMessage: undefined})}
                                onClearErrorMessage={() => this.setState({errorMessage:undefined})}
                                onClearWarnMessage={() => this.setState({warnMessage: undefined})}/>
            {this.getAufnahmeArea()}
            <div>
                <div className="grid" style={{
                    height: 300,
                    width: "100%",
                    margin: 0,
                    padding: 0,
                    backgroundColor: "#004080"
                }}>
                    <PlayerSingleArea player={this.state.players[0]}
                                      togo={this.getToGo(this.state.players[0])}
                                      isLegBeginner={this.state.beginnerLegPlayerid === this.state.players[0].playerid}/>
                    <PlayerSingleArea player={this.state.players[1]}
                                      togo={this.getToGo(this.state.players[1])}
                                      isLegBeginner={this.state.beginnerLegPlayerid === this.state.players[1].playerid}/>
                </div>
            </div>
            {!existiertEinGewinner ?
                this.getScoreArea(anzScores)
                :
                this.gameOverArea()
            }
            <AufnahmeShortcutDialog visible={this.state.showAufnahmeShortcutsDialog}
                                    callBackOnClose={() => this.setState({showAufnahmeShortcutsDialog: false})}/>
            <AnzahlWuerfeDialog visible={this.state.showAnzahlWuerfe} sendAnzahlWuerfe={this.sendAnzahlWuerfe}
                                callBackOnClose={() => this.setState({showAnzahlWuerfe: false})}/>
        </div>
    }

    getScoreArea(anzScores) {
        let mobileDevice = this.isMobileDevice();
        let style = mobileDevice ? {} : {borderLeft: "2px solid black", borderRight: "2px solid black"};
        return <div className="grid" style={{backgroundColor: "#e6f2ff", padding: 0, margin: 0, height: "100%"}}>
            {!mobileDevice && <StatistikArea orientation="left" player={this.state.players[0]}/>}
            <div className="col" style={style}>
                <ScoreHeader fontSize={20}/>
                <div className="grid"
                     style={{backgroundColor: "#e6f2ff"}}>
                    <div className="col">
                        <ScoreArea key={"score_area_" + this.state.players[0].playerid}
                                   scores={this.getGameDetails(this.state.players[0]).scoreDtos}
                                   playerid={this.state.players[0].playerid}
                                   changeAufnahmeAllowed={true} changeScore={this.changeScore} anzScores={anzScores}
                                   mobileDevice={mobileDevice}/>
                    </div>
                    <div className="col-fixed" style={{width: 70, margin: 0, padding: 0}}>
                        <WurfArea anzScores={anzScores} mobileDevice={mobileDevice}/>
                    </div>
                    <div className="col">
                        <ScoreArea key={"score_area_" + this.state.players[1].playerid}
                                   scores={this.getGameDetails(this.state.players[1]).scoreDtos}
                                   playerid={this.state.players[1].playerid}
                                   changeAufnahmeAllowed={true} changeScore={this.changeScore} anzScores={anzScores}
                                   mobileDevice={mobileDevice}/>
                    </div>
                </div>
            </div>
            {!mobileDevice && <StatistikArea orientation="right" player={this.state.players[1]}/>}
        </div>;
    }

    getGameDetails(player) {
        for (const gameDetailResultDto of player.gameDetailResultDtos) {
            if (!gameDetailResultDto.abgeschlossen) {
                return gameDetailResultDto;
            }
        }
        return undefined;
    }

    getPlayerScoreDtoByPlayers(players, playerid) {
        for (const player of players) {
            if (player.playerid === playerid) {
                return player;
            }
        }
        return undefined;
    }

    getPlayerScoreDto(playerid) {
        return this.getPlayerScoreDtoByPlayers(this.state.players, playerid);
    }

    existiertEinGewinner() {
        return this.state.gewonnenPlayerid !== null && this.state.gewonnenPlayerid !== undefined;
    }

    getToGo(player) {
        if (this.existiertEinGewinner()) {
            return 0;
        }
        const gameDetail = this.getGameDetails(player);
        return gameDetail.scoreDtos[gameDetail.scoreDtos.length - 1].togo;
    }

    changeAufnahme(e) {
        if (!isNaN(e.target.value) && e.target.value.length <= 3) {
            this.setState({aufnahme: e.target.value})
        }
    }

    gameOverAreaSmall() {
        let gewonnenPlayerLabel = "";
        if (this.state.gewonnenPlayerid !== undefined && this.state.gewonnenPlayerid !== null) {
            gewonnenPlayerLabel = "Spieler " + this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername + " hat gewonnen!";
        }

        let folgeGameId = this.state.folgeGameId;

        return <div className="grid" style={{
            height: "100%",
            width: "100%",
            fontSize: 15,
            color: "#77b300",
            fontWeight: "bold",
            marginTop: 20,
            padding: 20
        }}>
            <div className="col">
                {gewonnenPlayerLabel}
                <br/>
                {folgeGameId !== undefined && folgeGameId !== null && <>
                    <br/>
                    Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                    <br/>
                    <br/>
                    <img src="/images/dart_select.png" alt="Spiel starten"
                         style={{cursor: "pointer", width: 80, marginLeft: 30}} onClick={() => this.starteFolgeGame()}/>
                    <br/>
                </>
                }
                {this.state.trainingsessionId &&
                <NavLink to={NAVIGATION_ITEM.TRAINING_GAME_EDIT.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300}}/>
                </NavLink>
                }
                {this.state.funGameId &&
                <NavLink to={NAVIGATION_ITEM.FUNGAME_OVERVIEW.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300}}/>
                </NavLink>
                }
                {this.state.turnierId &&
                <NavLink to={NAVIGATION_ITEM.TURNIER.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                }
            </div>

        </div>;
    }

    gameOverArea() {
        let gewonnenPlayerLabel = "";
        if (this.state.gewonnenPlayerid !== undefined && this.state.gewonnenPlayerid !== null) {
            gewonnenPlayerLabel = "Spieler " + this.getPlayerScoreDto(this.state.gewonnenPlayerid).playername + " hat gewonnen!";
        }

        let folgeGameId = this.state.folgeGameId;

        return <div className="grid" style={{height: "100%", width: "100%", padding: 0, margin: 0}}>
            <StatistikArea player={this.state.players[0]}/>
            <div className="col" style={{color: "#77b300", fontSize: 25}}>
                {gewonnenPlayerLabel}
                <br/>
                {folgeGameId !== undefined && folgeGameId !== null && <>
                    <br/>
                    Es existiert ein Folgegame, bitte gehe jetzt in dieses Spiel!!!
                    <br/>
                    <br/>
                    <img src="/images/dart_select.png" alt="Spiel starten"
                         style={{cursor: "pointer", width: 80, marginLeft: 30}} onClick={() => this.starteFolgeGame()}/>
                    <br/>
                </>
                }
                {this.state.trainingsessionId &&
                <NavLink to={NAVIGATION_ITEM.TRAINING_GAME_EDIT.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                }
                {this.state.funGameId &&
                <NavLink to={NAVIGATION_ITEM.FUNGAME_OVERVIEW.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                }
                {this.state.turnierId &&
                <NavLink to={NAVIGATION_ITEM.TURNIER.route} style={{textDecoration: "none"}}>
                    <Button label="Zurück zur Übersicht..." style={{width: 300, height: 50, marginTop: 20}}/>
                </NavLink>
                }
            </div>
            <StatistikArea player={this.state.players[1]}/>
        </div>;
    }

    starteFolgeGame() {
        this.setState({gameId: this.state.folgeGameId});
        if (stompClient !== undefined) {
            stompClient.disconnect();
        }
        this.websocketConnect(this.state.folgeGameId);
    }

    getAufnahmeArea() {
        const existiertEinGewinner = this.existiertEinGewinner();
        if (existiertEinGewinner) {
            return "";
        }
        const aufnahmeKorrekt = this.state.aufnahme !== "" && parseInt(this.state.aufnahme) <= 180;
        let felder = [];
        felder.push(<span style={{marginLeft: 20, marginRight: 10}}>Aufnahme</span>);
        felder.push(<InputText value={this.state.aufnahme}
                               ref={(el) => this.aufnahmeInput = el}
                               onChange={(e) => this.changeAufnahme(e)}
                               style={{fontSize: 20, width: 60}}
                               onKeyDown={(e) => this.handelKeyDown(e)}/>);
        felder.push(<Button label="Absenden" disabled={!aufnahmeKorrekt} onClick={() => this.sendAufnahme()}
                            style={{marginRight: 20, marginLeft:20}}/>);
        felder.push(<Button icon="pi pi-info-circle" onClick={() => this.setState({showAufnahmeShortcutsDialog: true})}
                            style={{marginLeft: 10, marginRight: 10}}/>);
        felder.push(<span style={{fontSize:20}}>Modus: {getModusNameById(this.state.modusId)} - 'first to legs': {this.state.firstToLegs}</span>);
        let style;
        if( this.state.players[1].active) {
            felder.reverse();
            style = {textAlign:"right"};
        } else {
            style = {textAlign:"left"};
        }

        return <div className="grid align-center vertical-container" style={{
            height: 50,
            margin: 0,
            marginBottom: 10,
            padding: 0,
            width: "100%",
            backgroundColor: "#e6f2ff",
            ...style
        }}>
            <div className="col">
                {this.state.saving &&
                <div>Speichere...<i className="pi pi-spin pi-spinner" style={{fontSize: 50, marginLeft: 30}}/></div>}
                {!existiertEinGewinner && !this.state.saving &&
                <div>
                    {felder}
                </div>
                }
            </div>
        </div>
    }
}

TwoPlayerScoringView.propTypes = {
    gameid: PropTypes.string.isRequired,
};

export default TwoPlayerScoringView;
